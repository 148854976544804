<template>
    <div class="about-author-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="about-author-content">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/">Home</a></li>
                                <li class="breadcrumb-item active" aria-current="page">
                                    Privacy & Policy
                                </li>
                            </ol>
                        </nav>
                        <div v-html="aboutUsHtml">
                            <!-- Rendered HTML will be inserted here -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MarkdownIt from 'markdown-it';
import AboutService from '../../service/AboutService';

export default {
    data() {
        return {
            aboutUsData: null,
            aboutUsHtml: null,
        };
    },
    created() {
        this.fetchAboutUsData();
    },
    methods: {
        async fetchAboutUsData() {
            try {
                // Call the service method to fetch about us data
                const data = await AboutService.getPrivacyPolicy();
                this.aboutUsData = data;

                // Convert markdown to HTML using markdown-it
                const md = new MarkdownIt();
                this.aboutUsHtml = md.render(this.aboutUsData);

                // Log the data (you can remove this in production)
                // console.log('About Us Data:', this.aboutUsData);
            } catch (error) {
                // Handle error
                console.error('Error fetching about us data in component:', error);
            }
        },
    },
};
</script>

<style></style>
