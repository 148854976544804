<template>
    <div class="most-view-box">
        <div class="section-title">
            <h3 class="title">Finance News</h3>
        </div>
        <template v-if="isLoading">
            <div class="loader mx-auto"></div>
        </template>
        <template v-else>
            <div class="international-post-items" v-for="(data, index) in worldNewsNew.slice(0, 5)" :key="index">
                <div class="international-item">
                    <div class="meta-category">
                        <span>{{ formatDate(data.publish_date) }}</span>
                    </div>
                    <h5 class="title">
                        <router-link :to="{ name: 'WorldNews', params: { id: data.id, url: data.url } }">
                            {{ truncateText(data.title, 50) }}
                        </router-link>
                    </h5>
                    <div class="meta-user">
                        <span><i class="fal fa-user-circle"></i> {{ data.author }}</span>
                    </div>
                    <div class="text-thumb d-flex align-items-center">
                        <p>{{ truncateText(data.text, 100) }}</p>
                        <div class="thumb">
                            <img :src="data.image" alt="business" :style="{ width: '200px', height: 'auto' }" />
                        </div>
                    </div>
                    <!-- <ul>
                        <li><a href="#"><i class="far fa-eye"></i> 53,346</a></li>
                        <li><a href="#"><i class="far fa-heart"></i> 53,346</a></li>
                        <li><a href="#"><i class="fas fa-share"></i> 53,346</a></li>
                    </ul> -->
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import NewsService from "../../service/NewsService";


export default {
    data() {
        return {
            worldNewsNew: {
                attributes: {
                    // thumbnail: null
                }
            },
            isLoading: false
        };
    },
    methods: {
        async fetchFinanceNews() {
            const newsService = new NewsService();
            try {
                this.isLoading = true;
                const data = await newsService.getFinanceNews();
                this.worldNewsNew = data;
            } catch (error) {
                console.error('Error fetching news:', error);
            } finally {
                this.isLoading = false;
            }
        },
        truncateText(text, maxLength) {
            if (text.length > maxLength) {
                return text.slice(0, maxLength) + "...";
            }
            return text;
        },
        formatDate(dateString) {
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            return new Date(dateString).toLocaleDateString('en-US', options);
        },
    },
    mounted() {
        this.fetchFinanceNews();
    }
};
</script>

<style>
.loader {
    border: 16px solid #f3f3f3;
    /* Light grey */
    border-top: 16px solid #3498db;
    /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>