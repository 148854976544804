<template>
    <div>
        <drawer @toggleSidebar="toggleSidebar" :sidebar="sidebar" />
        <Header image="logo.png" @toggleSidebar="toggleSidebar" />

        <div class="video-style-post-2-area">
            <div class="container custom-container">
                <div class="video-style-box mt-40">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="section-title">
                                <h3 class="title">Events</h3>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <!-- Conditional Live Stream or Latest Video Embed -->
                            <div class="video-play-item">
                                <div class="video-play-thumb">
                                    <div class="video-wrapper">
                                        <iframe class="responsive-iframe" :src="currentVideoUrl" frameborder="0"
                                            allowfullscreen></iframe>
                                    </div>
                                </div>
                            </div>
                            <!-- End Live Stream or Latest Video Embed -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer-one />

    </div>
</template>

<script>
import Drawer from "../Mobile/Drawer.vue";
import Header from "../Utility/HeaderTwo/index";
import FooterOne from "../HomeTwo/Footer.vue";

import NewsService from "../../service/NewsService";
import axios from 'axios'
export default {
    components: { Header, FooterOne, Drawer },
    data() {
        return {
            liveStreamUrl: 'https://www.youtube.com/embed/live_stream?channel=UCPLECk9Uw7HATirXNnPr6lw',
            currentVideoUrl: '',
            recentNews: [],
            defaultVideo: '',
            modalOpen: false,
            videoId: '',
            isLiveStreaming: false,
            channelId: 'UCPLECk9Uw7HATirXNnPr6lw',
            apiKey: 'AIzaSyD97NILXBAfU2F_aNz1vYF0r0ytbjmVUiA'
        };
    },
    methods: {
        async fetchLiveNewsDefault() {
            const newsService = new NewsService();
            try {
                const data = await newsService.getLiveNews();
                this.defaultVideo = `https://tvwan.com.pg${data.attributes.video.data.attributes.url}`;
            } catch (error) {
                console.error("Error fetching news:", error);
            }
        },
        async checkisLive() {
            const newsService = new NewsService();
            try {
                const data = await newsService.checkisLive();
                // this.defaultVideo = `https://tvwan.com.pg${data.attributes.video.data.attributes.url}`
                // console.log(data.attributes.isLive);
                let isLive = data.attributes.isLive
                if (isLive === true) {
                    // console.log("true");
                    const apiUrl = `https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=${this.channelId}&type=video&eventType=live&key=${this.apiKey}`;
                    const response = await axios.get(apiUrl);
                    let videoId = response.data.items[0].id.videoId
                    this.currentVideoUrl =  `https://www.youtube.com/embed/${videoId}`
                    // console.log(videoId)

                } else {
                    // console.log("false");
                    // console.log(this.defaultVideo)
                    this.currentVideoUrl = this.defaultVideo;
                    // console.log(this.currentVideoUrl)

                }
            } catch (error) {
                console.error("Error fetching news:", error);
            }
        }
    },
    async mounted() {
        await this.fetchLiveNewsDefault();
        this.checkisLive();
    }
};
</script>

<style>
.video-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    background: #000;
}

.video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media (max-width: 767px) {
    .responsive-iframe {
        width: 100%;
        height: 100%;
    }
}
</style>