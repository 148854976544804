<template>
    <div>
        <drawer @toggleSidebar="toggleSidebar" :sidebar="sidebar" />
        <Header @toggleSidebar="toggleSidebar" />
        <about-author />

        <!-- <other /> -->
        <!--====== POST FORM PART START ======-->
        <!-- <div class="post-form-area">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10">
            <div class="section-title">
              <h3 class="title">Leave an opinion</h3>
            </div>
            <div class="post-form-box">
              <form action="#">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="input-box">
                      <input type="text" placeholder="Full name" />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="input-box">
                      <input type="text" placeholder="Email address" />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="input-box">
                      <textarea name="#" id="#" cols="30" rows="10"
                        placeholder="Tell us about your opinion…"></textarea>
                      <button class="main-btn" type="button">
                        POST OPINION
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div> -->
        <div class="add-area text-center">
            <!-- <a href="#">
        <a href="#"><img src="https://cms.tvwan.com.pg/uploads/728x90_6347b2ada5.png" alt="" /></a>
      </a> -->
        </div>
        <!--====== POST FORM PART ENDS ======-->
        <footer-one />
        <div class="go-top-area">
            <div class="go-top-wrap">
                <div class="go-top-btn-wrap">
                    <a href="#" class="go-top go-top-btn d-block">
                        <i class="fa fa-angle-double-up"></i>
                        <i class="fa fa-angle-double-up"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Drawer from "../Mobile/Drawer.vue";
import FooterOne from "../HomeTwo/Footer.vue";
// src\components\HomeTwo\Footer.vue

// import Header from "../Utility/Header/index";
import AboutAuthor from "./text.vue";
// import Other from "./Other.vue";

import Header from "../Utility/HeaderTwo/index.vue"
export default {
    components: {
        AboutAuthor,
        // Other,
        FooterOne,
        Drawer,
        Header,
    },
    data: () => ({
        sidebar: false,
    }),
    created() {
        document.addEventListener("scroll", this.topToBottom);
    },
    methods: {
        toggleSidebar() {
            this.sidebar = !this.sidebar;
        },
        topToBottom() {
            const result = document.querySelector(".go-top");
            if (
                document.body.scrollTop > window.innerHeight ||
                document.documentElement.scrollTop > window.innerHeight
            ) {
                result.classList.add("active");
            } else {
                result.classList.remove("active");
            }
        },
    },
};
</script>

<style></style>
