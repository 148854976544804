import http from "../http-common";

// let news_api_key = af5a3aad4f8c44deafe2933534afc882;

export default class AssetService {
    data() {
        return {
            data: null,
        };
    }

    async getLogo() {//used
        return await http.get(`/api/asset?populate=*`)
            .then(result => {
                // console.log(result.data.data.attributes.Header728x90.data); // This will log the response data
                const mostRecentNews = result.data.data.attributes.Logo.data; // Get the most recent news post from the response
                return mostRecentNews; // Return the most recent news post to be used in the component
            })
            .catch(error => {
                console.error("Error fetching asset:", error);
                throw error; // Rethrow the error so it can be handled in the component
            });
    }

    async getAuthor() {//used
        return await http.get(`/api/asset?populate=*`)
            .then(result => {
                // console.log(result.data.data.attributes.Header728x90.data); // This will log the response data
                const mostRecentNews = result.data.data.attributes.Author.data; // Get the most recent news post from the response
                return mostRecentNews; // Return the most recent news post to be used in the component
            })
            .catch(error => {
                console.error("Error fetching asset:", error);
                throw error; // Rethrow the error so it can be handled in the component
            });
    }
}