import http from "../http-common";

// import axios from 'axios';

const baseUrl = 'https://cms.tvwan.com.pg/api';

export default {
    async getAboutUsData() {
        try {
            const response = await http.get(`${baseUrl}/about-us`,
            );
            const result = response.data.data.attributes.AboutUs
            // console.log(result)

            return result;
        } catch (error) {
            console.error('Error fetching about us data:', error);
            throw error;
        }
    },

    async getPrivacyPolicy() {
        try {
            const response = await http.get(`${baseUrl}/tc`,
            );
            const result = response.data.data.attributes.text
            // console.log(result)

            return result;
        } catch (error) {
            console.error('Error fetching privacy policy data:', error);
            throw error;
        }
    },
};
