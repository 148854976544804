import http from "../http-common";

const baseUrl = 'https://cms.tvwan.com.pg/api';

export default {
    async getContactData() {
        try {
            const response = await http.get(`${baseUrl}/contact`,
            );
            // const Location = response.data.data.attributes.Location
            // const Contact = response.data.data.attributes.Contact
            // console.log(Location)
            // console.log(Contact)

            return response;
        } catch (error) {
            console.error('Error fetching Contact us data:', error);
            throw error;
        }
    },


    async postContactData(formData) {
        try {
            const response = await http.post(`${baseUrl}/contact-forms`, {
                data: formData,
            });
            return response.data;
        } catch (error) {
            console.error('Error submitting contact form:', error);
            throw error;
        }
    },
}