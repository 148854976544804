<template>
  <div class="finance-info-area pt-70 pb-90">
    <div class="container">
      <div class="col-lg-12">
        <div class="about-author-content">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/">Home</a></li>
              <li class="breadcrumb-item active" aria-current="page">
                Finance
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="row">
        <!-- All Tables in a Single Column -->
        <div class="col-lg-8">
          <!-- Stock Market Table -->
          <!-- Divider -->
          <div class="divider"></div>
          <div class="finance-info-item mt-30">
            <h3 class="title">Stock Market</h3>
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Index</th>
                  <th scope="col">Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>DOW JONES</td>
                  <td>36304.02</td>
                </tr>
                <tr>
                  <td>ASX 200</td>
                  <td>73654.303</td>
                </tr>
                <tr>
                  <td>ALL ORDS</td>
                  <td>733640.401</td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- Divider -->
          <div class="divider"></div>

          <!-- Commodities Table -->
          <div class="finance-info-item mt-30">
            <h3 class="title">Commodities</h3>
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Commodity</th>
                  <th scope="col">Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>GOLD</td>
                  <td>36304.02</td>
                </tr>
                <tr>
                  <td>COFFEE</td>
                  <td>73654.303</td>
                </tr>
                <tr>
                  <td>COCOA</td>
                  <td>733640.401</td>
                </tr>
                <tr>
                  <td>COPRA</td>
                  <td>36304.02</td>
                </tr>
                <tr>
                  <td>PALM OIL</td>
                  <td>73654.303</td>
                </tr>
                <tr>
                  <td>CRUDE OIL</td>
                  <td>733640.401</td>
                </tr>
                <tr>
                  <td>COPPER</td>
                  <td>733640.401</td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- Divider -->
          <div class="divider"></div>

          <!-- PNG Kina Table -->
          <div class="finance-info-item mt-30">
            <h3 class="title">PNG Kina</h3>
            <div v-if="isLoading" class="text-center">
              <div class="spinner-grow text-danger" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div v-else>
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Currency</th>
                    <th scope="col">Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>USD</td>
                    <td>{{ convertToTwoDecimalPlaces(kina.usd) }}</td>
                  </tr>
                  <tr>
                    <td>AUD</td>
                    <td>{{ convertToTwoDecimalPlaces(kina.aud) }}</td>
                  </tr>
                  <tr>
                    <td>EUR</td>
                    <td>{{ convertToTwoDecimalPlaces(kina.eur) }}</td>
                  </tr>
                  <tr>
                    <td>JPY</td>
                    <td>{{ convertToTwoDecimalPlaces(kina.jpy) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <FinanceNews />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommoditiesService from "../../service/CommoditiesService";
import NewsService from "../../service/NewsService";

import FinanceNews from "../../components/FinanceNews/index.vue"

export default {
  components: { FinanceNews },
  data() {
    return {
      isLoading: true,
      kina: {
        usd: null,
        aud: null,
        eur: null,
        jpy: null,
      },
      stock: {
        DJIA: null,
      },
      recentNews: {
        attributes: {
          // thumbnail: null
        }
      },
    };
  },
  methods: {
    async getKina() {
      const commoditiesService = new CommoditiesService();
      await commoditiesService
        .getKina()
        .then((data) => {
          this.kina.usd = data.USD;
          this.kina.aud = data.AUD;
          this.kina.eur = data.EUR;
          this.kina.jpy = data.JPY;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
    convertToTwoDecimalPlaces(number) {
      return Number(number).toFixed(2);
    },
    async fetchRecentNews() {
      const newsService = new NewsService();
      await newsService.getRecentNews()
        .then((data) => {
          this.recentNews = data;
          // console.log(this.recentNews)
        })
        .catch((error) => {
          console.error("Error fetching news:", error);
        });
    },
    truncateText(text, maxLength) {
      if (text.length > maxLength) {
        return text.slice(0, maxLength) + "...";
      }
      return text;
    },
  },
  mounted() {
    this.getKina();
    this.fetchRecentNews();

    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
  },
};
</script>

<style>
.finance-info-area {
  padding-top: 70px;
  padding-bottom: 90px;
}

.finance-info-item {
  margin-bottom: 30px;
}

.finance-info-item .title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody+tbody {
  border-top: 2px solid #dee2e6;
}

.divider {
  height: 25px;
  background: linear-gradient(to right, #3a3a3a, #8a2be2);
  margin: 30px 0;
}

.spinner-grow {
  width: 3rem;
  height: 3rem;
}
</style>
