<template>
  <div class="video-style-post-2-area">
    <div class="container custom-container">
      <div class="video-style-box mt-40">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title">
              <h3 class="title">Videos News</h3>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8">
            <!-- Conditional Live Stream or Latest Video Embed -->
            <div class="video-play-item">
              <div class="video-play-thumb">
                <div class="video-wrapper">
                  <iframe class="responsive-iframe" :src="currentVideoUrl" frameborder="0" allowfullscreen></iframe>
                </div>
              </div>
            </div>
            <!-- End Live Stream or Latest Video Embed -->
          </div>
          <div class="col-lg-4">
            <div class="video-play-sidebar-items">
              <template v-for="(data, index) in recentNews">
                <div :key="index" class="post-gallery-style-2">
                  <div class="post-gallery-thumb" @click="openModal(data)">
                    <img :src="`https://cms.tvwan.com.pg${data.attributes.cover.data.attributes.formats.thumbnail.url}`"
                      alt="gallery" style="width: 150px; height: 57px;" />
                  </div>
                  <div class="post-gallery-content">
                    <h5 class="title" @click="openModal(data)">
                      <a href="#">{{ data.attributes.title }}</a>
                    </h5>
                    <div class="meta-post-2-style">
                      <div class="meta-post-categores">
                        <a>{{ data.attributes.category.data.attributes.name }}</a>
                      </div>
                      <div class="meta-post-date">
                        <span>{{ new Date(data.attributes.createdAt).toLocaleDateString('en-GB') }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <!-- Modal -->
            <b-modal v-model="modalOpen" @hide="closeModal" hide-footer>
              <div class="embed-responsive embed-responsive-16by9">
                <iframe class="embed-responsive-item" :src="`https://www.youtube.com/embed/${videoId}`"
                  allowfullscreen></iframe>
              </div>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NewsService from "../../service/NewsService";
import axios from "axios";

export default {
  data() {
    return {
      liveStreamUrl: 'https://www.youtube.com/embed/live_stream?channel=UCPLECk9Uw7HATirXNnPr6lw',
      currentVideoUrl: '',
      recentNews: [],
      defaultVideo: '',
      modalOpen: false,
      videoId: '',
      isLiveStreaming: false,
      channelId: 'UCPLECk9Uw7HATirXNnPr6lw',
      apiKey: 'AIzaSyD97NILXBAfU2F_aNz1vYF0r0ytbjmVUiA'
    };
  },
  methods: {
    async checkLiveStreaming() {
      const apiUrl = `https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=${this.channelId}&type=video&eventType=live&key=${this.apiKey}`;

      try {
        const response = await axios.get(apiUrl);
        this.isLiveStreaming = response.data.items.length > 0;
        this.currentVideoUrl = this.isLiveStreaming ? this.liveStreamUrl : this.defaultVideo;
      } catch (error) {
        console.error('Error fetching live streaming status:', error);
        this.currentVideoUrl = this.defaultVideo;
      }
    },
    async fetchVideoNews() {
      const newsService = new NewsService();
      try {
        this.recentNews = await newsService.getVideoNews();
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    },
    async fetchLiveNewsDefault() {
      const newsService = new NewsService();
      try {
        const data = await newsService.getLiveNews();
        this.defaultVideo = `https://tvwan.com.pg${data.attributes.video.data.attributes.url}`;
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    },
    openModal(data) {
      this.videoId = data.attributes.videolink;
      this.modalOpen = true;
    },
    closeModal() {
      this.modalOpen = false;
      this.videoId = '';
    },
    getThumbnailUrl(data) {
      return `https://cms.tvwan.com.pg${data.attributes.cover.data.attributes.formats.thumbnail.url}`;
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString();
    },
    scheduleLiveCheck() {
      const options = { timeZone: 'Pacific/Port_Moresby', hour: 'numeric', minute: 'numeric', hour12: false };
      const formatter = new Intl.DateTimeFormat([], options);
      const currentTime = formatter.formatToParts(new Date());

      const currentHour = parseInt(currentTime.find(part => part.type === 'hour').value, 10);
      const currentMinutes = parseInt(currentTime.find(part => part.type === 'minute').value, 10);


      if (currentHour === 12 && currentMinutes >= 0 && currentMinutes <= 15) {
        // Your code to execute between 12:00 PM and 12:15 PM
        this.checkLiveStreaming();
        console.log("It's between 12:00 PM and 12:15 PM. Executing code...");
      } else if (currentHour === 18 && currentMinutes >= 0 && currentMinutes <= 59) {
        this.checkLiveStreaming();
        console.log("It's between 6:00 PM and 6:59 PM. Executing code...");
      } else {
        console.log("It's not the specified time.");
        this.currentVideoUrl = this.defaultVideo;
      }

      // setInterval(() => {
      //   const current = new Date();
      //   const currentHours = current.getHours();
      //   const currentMinutes = current.getMinutes();

      //   if ((currentHours === 14 && currentMinutes === 20) || (currentHours === 20 && currentMinutes === 0)) {
      //     this.checkLiveStreaming();
      //     console.log("Live now")
      //   } else {
      //     console.log("Not live, showing default video.")
      //     this.currentVideoUrl = this.defaultVideo;
      //   }
      // }, 60000); // Check every minute
    }
  },
  async mounted() {
    await this.fetchLiveNewsDefault();
    await this.fetchVideoNews();
    this.scheduleLiveCheck();
  }
};
</script>

<style>
.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #000;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 767px) {
  .responsive-iframe {
    width: 100%;
    height: 100%;
  }
}
</style>
