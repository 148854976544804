<template>
  <div class="about-author-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="about-author-content">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="/">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">
                  About Us
                </li>
              </ol>
            </nav>
            <div class="author-content">
              <div class="thumb">
                <img :src="logo" alt="logo" width=auto height=auto />
              </div>
              <h3 class="title">TVWAN</h3>
              <p>{{ aboutUsData }}</p>
              <!-- <ul>
                <li>News Writer</li>
                <li>
                  <span>Since: <span>April 25, 2020</span></span>
                </li>
              </ul> -->
              <!-- <div class="text">
                <p>
                  TVWAN is the #1 source for Pacific and global content providing the best coverage of news,
                  sports, entertainment, lifestyle, business, community and events. With our trusted team of journalists
                  working locally in each market, we strive to bring you all the breaking and most up-to-date coverage of
                  events, from a team you can trust.
                </p>
                <p>
                  TVWAN has begun to revolutionize how the people across the Pacific can access their local and
                  regional news with their free to browse platform. Now, Digicel customers in Fiji, Samoa, Tonga, Vanuatu
                  & Nauru can receive the latest news without data or wifi.
                </p>
                <p>
                  Pacific news has never been so social with Loop's social and article commenting features. Readers can
                  share and shape the stories as they happen by posting stories, videos and photos. TVWAN is also
                  an application available for Android, iPhone and Windows phones.
                </p>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import AboutService from '../../service/AboutService';
import AssetService from "../../service/AssetService";


export default {
  data() {
    return {
      aboutUsData: null,
      logo: ''
    };
  },
  created() {
    this.fetchAboutUsData();
    this.fetchLogo();
  },
  methods: {
    async fetchAboutUsData() {
      try {
        // Call the service method to fetch about us data
        this.aboutUsData = await AboutService.getAboutUsData();

        // Log the data (you can remove this in production)
        // console.log('About Us Data:', //this.aboutUsData.data.attributes.AboutUs);
      } catch (error) {
        // Handle error
        console.error('Error fetching about us data in component:', error);
      }
    },
    async fetchLogo() {
      const assetService = new AssetService();
      const prefix = "https://cms.tvwan.com.pg";

      await assetService.getLogo()
        .then((data) => {
          const logo = prefix + data.attributes.url
          this.logo = logo;
          // console.log(this.logo)

        })
        .catch((error) => {
          console.error("Error fetching logo:", error);
        });
    },
  },
};
</script>

<style>
.author-content .thumb {
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  overflow: hidden;
  height: 115px;
  width: 115px;
  text-align: center;
  line-height: 112px;
  border: 2px solid #e4e5e6;
}
</style>
