/* global gtag */
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueSweetalert2 from 'vue-sweetalert2';
import BootstrapVuePlugin from 'bootstrap-vue';

import 'sweetalert2/dist/sweetalert2.min.css';
import '../src/assets/css/bootstrap.min.css';
import '../src/assets/css/font-awesome.min.css';
import '../src/assets/css/magnific-popup.css';
import '../src/assets/css/default.css';
import '../src/assets/css/style.css';
import '../src/assets/css/custom.css';
import store from './store';
import './registerServiceWorker'

Vue.config.productionTip = false;

// Use Sweetalert2 plugin
Vue.use(VueSweetalert2);
Vue.use(BootstrapVuePlugin);

router.afterEach((to, from) => {// eslint-disable-line no-unused-vars
  // Google Analytics page view tracking
  if (typeof gtag !== 'undefined') {
    gtag('config', 'G-SCS2VZ65ZP', {
      page_path: to.fullPath,
    });
  }
});

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app');
