<template>
  <contact />
</template>

<script>
import contact from "../components/Contacts/index.vue";
export default {
  components: { contact },
};
</script>

<style></style>
