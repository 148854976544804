// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyCF4gvimBhJkIIV8wmaiIHIjqyVjeWTS5w",
  authDomain: "tvwan-1bf32.firebaseapp.com",
  projectId: "tvwan-1bf32",
  storageBucket: "tvwan-1bf32.appspot.com",
  messagingSenderId: "972858005444",
  appId: "1:972858005444:web:169c33c39582181a2fb691",
  measurementId: "G-SCS2VZ65ZP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

export { auth };
