<template>
    <div>
        <privacy />
    </div>
</template>

<script>
import Privacy from "../components/Privacy/index";
export default {
    components: { Privacy },
};
</script>

<style></style>
