<template>
  <div class="contact-info-area pt-70 pb-90">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="about-author-content">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="/">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">
                  Contact Us
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6">
          <div class="contact-info-item mt-30">
            <h3 class="title">Location</h3>
            <span><i class="fas fa-map-marker-alt"></i> LOCATION:</span>
            <ul>
              {{ data.location }}
            </ul>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="contact-info-item mt-30">
            <h3 class="title">Call</h3>
            <span><i class="fas fa-phone"></i> CALL NOW:</span>
            <ul>
              {{ data.contact }}
            </ul>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="contact-info-item mt-30">
            <h3 class="title">Email</h3>
            <span><i class="fas fa-envelope"></i> EMAIL:</span>
            <ul>
              {{ data.email }}
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="map-area">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15756.67678445715!2d147.1894226!3d-9.4445981!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x690230d395f79f65%3A0x8f890eb1c5b47cb7!2sDigicel!5e0!3m2!1sen!2sbd!4v1707100961375!5m2!1sen!2sbd"
              width="600" height="450" style="border: 0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Contact from '../../service/Contact';

export default {
  data() {
    return {
      // locationData: null,
      // contactData: null,
      // emailData:null,
      data: {
        location: null,
        contact: null,
        email: null,
      }
    };
  },
  created() {
    this.fetchContactUsData();
  },
  methods: {
    async fetchContactUsData() {
      try {
        const result = await Contact.getContactData();

        this.data.location = result.data.data.attributes.Location
        this.data.contact = result.data.data.attributes.Contact
        this.data.email = result.data.data.attributes.Email
        //this.contactData = await Contact.getContactData();
        //this.emailData = await Contact.getContactData();
        // console.log(result.data.data.attributes.Location)
        // console.log(this.locationData)

      } catch (error) {
        console.error('Error fetching Contact us data component', error);
      }
    }
  }
};
</script>

<style></style>
