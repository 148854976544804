<template>
  <div>
    <header class="header-area header-style-2 header-style-4">
      <top-bar />
      <div class="header-centerbar">
        <div class="container custom-container">
          <div class="row align-items-center">
            <div class="col-lg-2 col-md-5 col-8">
              <div class="logo">
                <router-link to="/">
                  <img :src="logo" alt="logo" width="150" height="90" />
                </router-link>
              </div>
            </div>
            <div class="col-lg-8 col-md-7">
              <div class="header-search">
                <form @submit.prevent="search">
                  <div class="input-box">
                    <input type="text" v-model="query" placeholder="What you want to search?" @input="fetchResults"
                      style="border-radius: 10px;" />
                    <button type="submit">Search</button>
                  </div>
                </form>
                <div class="search-results" v-if="results.length">
                  <ul>
                    <li v-for="(result, index) in results" :key="index">
                      <router-link :to="'/news/' + result.id">
                        {{ result.attributes.title }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-2 d-none d-lg-block">
              <div class="header-temperature justify-content-end d-flex align-items-center">
                <div class="icon">
                  <img src="@/assets/images/temperature-icon.svg" alt="" />
                </div>
                <div class="temperature-content text-center">
                  <h5 class="title">
                    {{ temperature }}<sup class="temperature-sup"> 0<sub>C</sub></sup>
                  </h5>
                  <p>{{ location }}</p>
                </div>
              </div>
            </div>
            <div class="col-4 d-md-none d-flex justify-content-end">
              <div class="header-temperature align-items-center">
                <div class="icon">
                  <img src="@/assets/images/temperature-icon.svg" alt="" />
                </div>
                <div class="temperature-content text-center">
                  <h5 class="title">
                    {{ temperature }}<sup class="temperature-sup"> 0<sub>C</sub></sup>
                  </h5>
                  <p>{{ location }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="header-menubar">
        <div class="container custom-container">
          <div class="menubar-bg" style="background: linear-gradient(45deg, #fe402a 0%, #a101ff 100%);">
            <div class="row align-items-center">
              <div class="col-lg-9 col-sm-3 col-3">
                <svg class="menu-drawer" style="cursor: pointer" @click.prevent="hideSidebar"
                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" fill="white" height="24">
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M3 4h18v2H3V4zm6 7h12v2H9v-2zm-6 7h18v2H3v-2z"></path>
                </svg>
                <nav-items style="margin-left: 10px" class="header-two" />
              </div>
              <div class="col-lg-3 col-sm-9 col-9">
                <div class="header-menu-rightbar">
                  <div class="header-date">
                    <p>{{ currentDate }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import NavItems from "../Common/NavItems.vue";
import TopBar from "./TopBar.vue";
import AdService from "../../../service/AdvertisementService";
import AssetService from "../../../service/AssetService";

export default {
  components: { NavItems, TopBar },
  methods: {
    async fetchResults() {
      if (this.query.length < 3) {
        this.results = [];
        return;
      }
      try {
        const response = await fetch(`https://cms.tvwan.com.pg/api/newsposts?populate=*&filters[title][$contains]=${encodeURIComponent(this.query)}&sort=createdAt:DESC&pagination[pageSize]=5`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${this.token}`,
            'Content-Type': 'application/json'
          }
        });
        const data = await response.json();
        this.results = data.data;
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    },
    async search() {
      try {
        const response = await fetch(`https://cms.tvwan.com.pg/api/newsposts?populate=*&filters[title][$contains]=${encodeURIComponent(this.query)}&sort=createdAt:DESC&pagination[pageSize]=5`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${this.token}`,
            'Content-Type': 'application/json'
          }
        });
        const data = await response.json();
        this.results = data.data;
      } catch (error) {
        console.error('Error performing search:', error);
      }
    },
    handleResultClick(result) {
      console.log(result.id);
    },
    hideSidebar(e) {
      this.$emit("toggleSidebar", e);
    },
    goToLink(link) {
      window.location.href = link;
    },
    async fetchBanners() {
      const adService = new AdService();
      const prefix = "https://cms.tvwan.com.pg";
      let url

      await adService.getAdvertismentsURL()
        .then((data) => {
          url = data
        })
        .catch((error) => {
          console.error("Error fetching url:", error);
        });

      try {
        const data = await adService.getAdvertismentsHeader();
        const slides = data.map(entry => ({
          src: prefix + entry.attributes.url,
          link: url
        }));
        this.slides = slides;
      } catch (error) {
        console.error("Error fetching banners:", error);
      }
    },
    async fetchLogo() {
      const assetService = new AssetService();
      const prefix = "https://cms.tvwan.com.pg";

      await assetService.getLogo()
        .then((data) => {
          const logo = prefix + data.attributes.url
          this.logo = logo;
        })
        .catch((error) => {
          console.error("Error fetching logo:", error);
        });
    },
  },
  data() {
    return {
      currentDate: '',
      temperature: '',
      location: '',
      slide: 0,
      slides: [],
      logo: '',
      query: '',
      results: [],
      token: 'f219eb0070a2ffa222461f263769af697a6a42c6fc5abdbebb9a7247ead2f6172e40f2d94a7428ffbfee83c971b6f21122a2fd036fe50a381d80e611b65d22680ec131b20d6a539226b10951031000bd5defb8703b42108fe2bf193d3ae37150e4e133a6356a6109b0a76577b20785f9493b5bd3d7bb4636a9f30d25e5fd49a0'
    }
  },
  mounted() {
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString('en-US', {
      weekday: 'long',
      month: 'long',
      day: 'numeric'
    });
    this.currentDate = formattedDate;

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        const apiKey = '0d057b5c8b455ac4bb3c6522e258aee5';
        const apiUrl = `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&units=metric&appid=${apiKey}`;

        fetch(apiUrl)
          .then(response => response.json())
          .then(data => {
            this.temperature = data.main.temp;
            this.location = data.name;
          })
          .catch(error => {
            console.error('Error:', error);
          });
      },
      (error) => {
        console.error('Error:', error);
      }
    );

    this.fetchBanners();
    this.fetchLogo();
  }
};
</script>

<style scoped>
/* Hide logo and carousel on screens smaller than 768px (md) */
@media (max-width: 767.98px) {
  .d-md-block {
    display: none !important;
  }

  .header-temperature {
    display: none !important;
  }

  .header-search {
    position: relative;
  }

  .search-results {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #ccc;
    border-radius: 10px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
  }

  .search-results ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .search-results li {
    padding: 10px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
  }

  .search-results li:last-child {
    border-bottom: none;
  }

  .search-results li {
    cursor: pointer;
  }
}
</style>
