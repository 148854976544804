<template>
  <div>
    <footer class="footer-style-2-area">
      <div class="container custom-container">
        <div class="row">
          <div class="col-lg-4">
            <div class="widget widget-about">
              <a href="#"><img :src="logo" alt="" width="100" length=auto /></a>
              <p>{{ aboutUsData }}</p>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="footer-top-info">
              <div class="row">
                <div class="col-md-4">
                  <div class="footer-info-item">
                    <h3 class="title">Let’s talk</h3>
                    <ul>
                      {{ data.contact }}
                    </ul>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="footer-info-item">
                    <h3 class="title">Let’s chat</h3>
                    <ul>
                      {{ data.email }}
                    </ul>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="footer-info-item">
                    <h3 class="title">Headquarters</h3>
                    <ul>
                      {{ data.location }}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-40">
              <b-carousel id="my-carousel2" v-model="slide2" :interval="4000">
                <b-carousel-slide v-for="(slide, index) in slides" :key="index" :img-src="slide.src">
                </b-carousel-slide>
              </b-carousel>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <div class="footer-copyright footer-copyright-style-2">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="
                copyright-item
                d-block d-md-flex
                justify-content-between
                align-items-center
              ">
              <p>© Copyright {{ getCurrentYear() }} TVWAN | All Rights Reserved</p>
              <ul>
                <li><a href="/about-us">About</a></li>
                <li><a href="/privacy-policy">Privacy & Policy</a></li>
                <li><a href="/contact">Contact Us</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AboutService from '../../service/AboutService';
import Contact from '../../service/Contact';
import AdService from "../../service/AdvertisementService";
import AssetService from "../../service/AssetService";

export default {
  data() {
    return {
      aboutUsData: null, // Initialize with an empty object
      data: {
        location: null,
        contact: null,
        email: null,
      },
      slide2: 0,
      slides: [],
      logo: '',
      logoLoaded: false, // Track if logo is loaded
    };
  },
  created() {
    this.fetchAboutUsData();
    this.fetchContactUsData();
    this.fetchBanners();
    this.fetchLogo();
  },
  methods: {
    goToLink(link) {
      window.location.href = link;
    },
    getCurrentYear() {
      return new Date().getFullYear();
    },
    async fetchAboutUsData() {
      try {
        this.aboutUsData = await AboutService.getAboutUsData();
      } catch (error) {
        console.error('Error fetching about us data:', error);
      }
    },
    async fetchLogo() {
      const assetService = new AssetService();
      const prefix = "https://cms.tvwan.com.pg";
      try {
        const data = await assetService.getLogo();
        this.logo = prefix + data.attributes.url;
        this.logoLoaded = true;
      } catch (error) {
        console.error("Error fetching logo:", error);
        this.logoLoaded = false;
      }
    },
    async fetchContactUsData() {
      try {
        const result = await Contact.getContactData();
        this.data.location = result.data.data.attributes.Location;
        this.data.contact = result.data.data.attributes.Contact;
        this.data.email = result.data.data.attributes.Email;
      } catch (error) {
        console.error('Error fetching contact us data:', error);
      }
    },
    async fetchBanners() {
      const adService = new AdService();
      const prefix = "https://cms.tvwan.com.pg";
      try {
        const url = await adService.getAdvertismentsURL();
        const data = await adService.getAdvertismentsFooter();
        this.slides = data.map(entry => ({ src: prefix + entry.attributes.url, link: url }));
      } catch (error) {
        console.error("Error fetching banners:", error);
      }
    }
  }
};
</script>

<style>
.widget-about {
  width: 100%;
  max-width: 1200px;
  padding: 20px;
  margin: 0 auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.widget-about img {
  width: 150px;
  height: auto;
  display: block;
  margin: 0 auto 20px;
}

.widget-about p {
  text-align: justify;
  line-height: 1.6;
}
</style>