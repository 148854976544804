<template>
  <section class="all-post-style-2-area">
    <div class="container custom-container">
      <div class="row">
        <div class="col-lg-8">
          <div class="treding-post-news mt-40">
            <div class="section-title">
              <h3 class="title">Most Recent</h3>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="post-news-item-2">
                  <div class="post-news-thumb">
                    <router-link :to="'/news/' + newsData.id">
                      <img :src="this.newsData.smallImg" alt="" />
                    </router-link>
                  </div>
                  <div class="post-news-content">
                    <h3 class="title">
                      <!-- <router-link to="/posts/postOne">{{ newsData && newsData.attributes.title }}</router-link> -->
                      <router-link :to="'/news/' + (newsData.id)">{{ newsData && newsData.attributes.title
                        }}</router-link>
                    </h3>
                    <p>
                      {{ truncateText(newsData && newsData.attributes.body, 200) }}
                    </p>
                    <div class="meta-post-2-style" style="font-size: 16px;">
                      <div class="meta-post-categores">
                        <a href="#" style="font-size: 16px;">
                          {{ newsData && newsData.attributes.category.data.attributes.name }}
                        </a>
                      </div>
                      <div class="meta-post-date">
                        <span style="font-size: 16px;">
                          {{ formattedDate }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="trending-sidebar-slider position-relative">
                  <!-- <span @click="trendingPrev" class="prev slick-arrow" style="display: block">
                    <i class="fal fa-angle-left"></i>
                  </span> -->
                  <slider :settings="trendingSetting" ref="trending">
                    <div v-for="(slideData, slideIndex) in chunkedRecentNews" :key="slideIndex"
                      class="post_gallery_items">
                      <div v-for="(data, index) in slideData" :key="index" class="post-gallery-style-2">
                        <!-- <div class="post-gallery-thumb">
                          <img
                            :src="`http://localhost:1337${data.attributes.image.data[0].attributes.formats.thumbnail.url}`"
                            alt="gallery" />
                        </div> -->
                        <div class="post-gallery-thumb">
                          <router-link :to="'/news/' + data.id">
                            <img
                              :src="`https://cms.tvwan.com.pg${data.attributes.image.data[0].attributes.formats.thumbnail.url}`"
                              alt="gallery" class="rounded-image" />
                          </router-link>
                        </div>
                        <div class="post-gallery-content">
                          <h5 class="title">
                            <!-- <router-link :to="'/posts/' + data.id">{{ truncateText(data.attributes.title, 50)
                            }}</router-link> -->
                            <router-link :to="'/news/' + (data.id)">{{ truncateText(data.attributes.title, 50)
                              }}</router-link>
                          </h5>
                          <div class="meta-post-2-style">
                            <div class="meta-post-categores">
                              <a href="#">{{ data.attributes.category.data.attributes.name }}</a>
                            </div>
                            <div class="meta-post-date">
                              <span>{{ new Date(data.attributes.createdAt).toLocaleDateString('en-GB') }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </slider>
                  <!-- <span @click="trendingNext" class="next slick-arrow" style="display: block"><i
                      class="fal fa-angle-right"></i></span> -->
                </div>
              </div>
            </div>
          </div>
          <div class="feature-news-box mt-40">
            <div class="section-title">
              <h3 class="title">Featured News</h3>
            </div>
            <div class="feature-slider position-relative">
              <span @click="featuresPrev" class="prev slick-arrow" style="display: block"><i
                  class="fal fa-angle-left"></i></span>
              <slider class="row" ref="features" :settings="featureSettings">
                <template v-for="(data, index) in featuredNews">
                  <div :key="index" class="px-3">
                    <div class="feature-news-item">
                      <div class="feature-news-thumb">
                        <router-link :to="'/news/' + data.id">
                          <img
                            :src="`https://cms.tvwan.com.pg${data.attributes.image.data[0].attributes.formats.thumbnail.url}`"
                            alt="gallery" class="rounded-image" />
                        </router-link>

                        <div class="meta-categores">
                          <span>{{ data.attributes.category.data.attributes.name }}</span>
                        </div>
                      </div>
                      <div class="feature-news-content">
                        <p>
                          <!-- {{ truncateText(data.attributes.body, 150) }} -->
                          <router-link :to="'/news/' + (data.id)">{{ truncateText(data.attributes.body, 150)
                            }}</router-link>
                        </p>
                      </div>
                    </div>
                  </div>
                </template>
              </slider>
              <span @click="featuresNext" class="next slick-arrow" style="display: block"><i
                  class="fal fa-angle-right"></i></span>
            </div>
          </div>
          <div class="bussiness-box mt-40">
            <div class="section-title">
              <h3 class="title">Business News</h3>
            </div>
            <div class="bussiness-items">
              <template v-for="(data, index) in businessNews.slice(0, 3)">
                <div :key="index" class="bussiness-post-item">
                  <div class="bussiness-post-thumb">
                    <router-link :to="'/news/' + data.id">
                      <img
                        :src="`https://cms.tvwan.com.pg${data.attributes.image.data[0].attributes.formats.small.url}`"
                        alt="business" />
                    </router-link>
                  </div>
                  <div class="bussiness-post-content">
                    <h3 class="title">
                      <!-- <router-link to="/posts/postOne">{{ data.attributes.title }}</router-link> -->
                      <router-link :to="'/news/' + (data.id)">{{ truncateText(data.attributes.title, 50)
                        }}</router-link>
                    </h3>
                    <div class="meta-date-link">
                      <span>{{ formatDate(data.attributes.createdAt) }}</span>
                      <!-- <ul>
                        <li>
                          <a href="#"><i class="fal fa-bookmark"></i></a>
                        </li>
                        <li>
                          <a href="#"><i class="fas fa-share"></i></a>
                        </li>
                      </ul> -->
                    </div>
                    <p>
                      {{ truncateText(data.attributes.body, 200) }}
                    </p>
                    <!-- <a href="#">LEARN MORE <img src="@/assets/images/arrow-2.svg" alt="" /></a> -->
                  </div>
                </div>
              </template>
            </div>
            <div class="bussiness-btn">
              <a class="main-btn main-btn-2" href="/category/business/">See more</a>
            </div>
            <div class="mt-40">
              <b-carousel id="my-carousel" v-model="slide" :interval="4000">
                <b-carousel-slide
                  v-for="(slideItem, index) in slides"
                  :key="index"
                  :img-src="slideItem.src"
                  class="carousel-slide"
                >
                  <div
                    class="overlay"
                    @click="goToLink(slideItem.link)"
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 10; cursor: pointer;"
                  ></div>
                </b-carousel-slide>
              </b-carousel>
            </div>

            
          </div>
        </div>
        <div class="col-lg-4">
          <div class="all-post-sidebar-style-2 mt-40">
            <WorldNews />
          </div>

          <div class="ad mt-40">
            <!-- <b-carousel id="my-carousel2" v-model="slide2" :interval="4000">
              <b-carousel-slide v-for="(slide2, index) in slides2" :key="index" :img-src="slide2.src">
              </b-carousel-slide>
            </b-carousel> -->


            <b-carousel id="my-carousel2" v-model="slide2" :interval="4000">
              <b-carousel-slide
                v-for="(slideItem, index) in slides2"
                :key="index"
                :img-src="slideItem.src"
                class="carousel-slide"
              >
                <div
                  class="overlay"
                  @click="goToLink(slideItem.link)"
                  style="top: 0; left: 0; width: 300px; height: 250px; z-index: 10; cursor: pointer;"
                ></div>
              </b-carousel-slide>
            </b-carousel>
          </div>

          <div class="finance-box mt-40">
            <div class="section-title">
              <h3 class="title">Technology</h3>
            </div>
            <div class="finance-items">
              <!-- <template v-for="(data, index) in finance.slice(0, 4)">
                  <divide-card :datas="data" :key="index" stype="col-style-2" />
                </template> -->

              <template v-for="( data, index ) in technologyNews.slice(0, 2) ">
                <div :key="index" class="finance-item">
                  <div class="finance-thumb">
                    <router-link :to="'/news/' + data.id">
                      <img
                        :src="`https://cms.tvwan.com.pg${data.attributes.image.data[0].attributes.formats.small.url}`"
                        alt="finance" />
                    </router-link>
                    <!-- <div class="finance-date">
                        <span>{{ datas.category.name }}</span>
                      </div> -->
                  </div>
                  <div class="finance-content">
                    <h3 class="title">
                      <!-- <router-link to="/posts/postOne">{{ data.attributes.title }}</router-link> -->
                      <router-link :to="'/news/' + (data.id)">{{ truncateText(data.attributes.title, 50)
                        }}</router-link>
                    </h3>
                    <p class="line-clumb">
                      {{ data.attributes.body }}
                    </p>
                    <!-- <ul>
                      <li>
                        <a href="#"><i class="far fa-eye"></i> 53,346</a>
                      </li>
                      <li>
                        <a href="#"><i class="far fa-heart"></i> 53,346</a>
                      </li>
                      <li>
                        <a href="#"><i class="fas fa-share"></i> 53,346</a>
                      </li>
                    </ul> -->
                  </div>
                </div>

              </template>
            </div>
            <div class="finance-btn text-center mt-25">
              <a class="main-btn main-btn-2" href="/category/technology/">See more</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Slider from "../Helpers/Slider.vue";
// import RowCard from "../Utility/Cards/RowCard.vue";

import NewsService from "../../service/NewsService";
import AdService from "../../service/AdvertisementService";


import WorldNews from "../../components/WorldNews/index.vue"


export default {
  components: { Slider, WorldNews },
  props: {
    trending: {
      type: Array,
    },
    features: {
      type: Array,
    },
    businessPost: {
      type: Array,
    },
    mostViews: {
      type: Array,
    },
    finance: {
      type: Array,
    },
  },
  data: () => ({
    trendingSetting: {
      autoplay: true,
      dots: false,
      arrows: false,
    },
    featureSettings: {
      autoplay: true,
      dots: false,
      arrows: false,
      slidesToScroll: 1,
      slidesToShow: 3,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slideToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slideToScroll: 1,
          },
        },
      ],
    },
    postGalleryView: {
      autoplay: true,
      dots: false,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
    },
    newsData: {
      attributes: {
        createdAt: null,
        smallImg: null
      }
    },
    featuredNews: {
      attributes: {
        thumbnail: null,
      }
    },
    recentNews: {
      attributes: {
        // thumbnail: null
      }
    },
    businessNews: {
      attributes: {
        // thumbnail: null
      }
    },
    technologyNews: {
      attributes: {
        // thumbnail: null
      }
    },
    worldNews: {
      attributes: {
        // thumbnail: null
      }
    },
    worldNewsNew: {
      attributes: {
        // thumbnail: null
      }
    },
    slide: 0,
    slides: [],
    slide2: 0,
    slides2: [],
  }
  ),
  methods: {
    trendingPrev() {
      this.$refs.trending.prev();
    },
    trendingNext() {
      this.$refs.trending.next();
    },
    featuresPrev() {
      this.$refs.features.prev();
    },
    featuresNext() {
      this.$refs.features.next();
    },
    postGalleryPrev() {
      this.$refs.postGallery.prev();
    },
    postGalleryNext() {
      this.$refs.postGallery.next();
    },
    goToLink(link) {
      window.location.href = link;
    },
    async fetchNews() {
      const newsService = new NewsService();
      await newsService.getTrendingNews()
        .then((data) => {
          this.newsData = data;
          this.newsData.createdAt = data.attributes.createdAt
          this.newsData.smallImg = 'https://cms.tvwan.com.pg' + data.attributes.image.data[0].attributes.formats.small.url
        })
        .catch((error) => {
          console.error("Error fetching news:", error);
        });
    },
    async fetchRecentNews() {
      const newsService = new NewsService();
      await newsService.getRecentNews()
        .then((data) => {
          this.recentNews = data;
        })
        .catch((error) => {
          console.error("Error fetching news:", error);
        });
    },
    async fetchFeaturedNews() {
      const newsService = new NewsService();
      await newsService.getFeaturedNews()
        .then((data) => {
          this.featuredNews = data;
        })
        .catch((error) => {
          console.error("Error fetching news:", error);
        });
    },
    async fetchBusinessNews() {
      const newsService = new NewsService();
      await newsService.getBusinessNews()
        .then((data) => {
          this.businessNews = data;
        })
        .catch((error) => {
          console.error("Error fetching news:", error);
        });
    },
    async fetchTechnologyNews() {
      const newsService = new NewsService();
      await newsService.getTechnologyNews()
        .then((data) => {
          this.technologyNews = data;
        })
        .catch((error) => {
          console.error("Error fetching news:", error);
        });
    },
    // async fetchWorldNews() {
    //   const newsService = new NewsService();
    //   await newsService.getWorldNews()
    //     .then((data) => {
    //       this.worldNews = data;
    //       // console.log(this.worldNews[0].author)
    //     })
    //     .catch((error) => {
    //       console.error("Error fetching news:", error);
    //     });
    // },
    async fetchWorldNewsNew() {
      const newsService = new NewsService();

      try {
        await newsService.getWorldNewsNew()
          .then((data) => {
            this.worldNewsNew = data;
            // console.log("Yeet", this.worldNewsNew[0].author)
          })
      } catch (error) {
        // Handle error if needed
        console.error('Error fetching news:', error);

        //   .catch((error) => {
        //   console.error("Error fetching news:", error);
        // });
      }
    },
    truncateText(text, maxLength) {
      if (text.length > maxLength) {
        return text.slice(0, maxLength) + "...";
      }
      return text;
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString('en-US', options);
    },
    updateString() {
      this.$store.commit('updateString', 'Updated String from ParentComponent!');
      console.log('String updated!');
    },
    async fetchBanners() {
      const adService = new AdService();
      const prefix = "https://cms.tvwan.com.pg";

      let url

      await adService.getAdvertismentsURL()
        .then((data) => {
          url = data
        })
        .catch((error) => {
          console.error("Error fetching url:", error);
        });

      await adService.getAdvertismentsBody()
        .then((data) => {
          const slides = data.map(entry => ({ src: prefix + entry.attributes.url, link: url }));
          this.slides = slides;
        })
        .catch((error) => {
          console.error("Error fetching body:", error);
        });
    },
    async fetchBanners2() {
      const adService = new AdService();
      const prefix = "https://cms.tvwan.com.pg";

      let url

      await adService.getAdvertismentsURL()
        .then((data) => {
          url = data
        })
        .catch((error) => {
          console.error("Error fetching url:", error);
        });

      await adService.getAdvertismentsBody2()
        .then((data) => {
          const slides = data.map(entry => ({ src: prefix + entry.attributes.url, link: url }));
          this.slides2 = slides;
        })
        .catch((error) => {
          console.error("Error fetching body:", error);
        });
    },
  },
  mounted() {
    this.fetchNews();
    this.fetchRecentNews();
    this.fetchFeaturedNews();
    this.fetchBusinessNews();
    this.fetchTechnologyNews();
    // this.fetchWorldNews();
    this.fetchWorldNewsNew();
    this.fetchBanners();
    this.fetchBanners2();

  },
  computed: {
    formattedDate() {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(this.newsData.attributes.createdAt).toLocaleDateString('en-US', options);
    },
    chunkedRecentNews() {
      const chunkSize = 5;
      const chunkedArray = [];

      for (let i = 0; i < this.recentNews.length; i += chunkSize) {
        chunkedArray.push(this.recentNews.slice(i, i + chunkSize));
      }
      return chunkedArray;
    },
    filteredNewsData() {
      if (this.businessNews && this.businessNews.data && Array.isArray(this.businessNews.data)) {
        const filteredData = this.businessNews.data.filter(item => item.attributes.category.data !== null);
        console.log('Filtered Data:', filteredData); // Add this line to log the filtered data
        return filteredData;
      } else {
        return [];
      }
    },
  },
};
</script>

<style scoped>
.post-gallery-thumb {
  width: 100px;
  /* Set the desired width for the container */
  height: 80px;
  /* Set the desired height for the container */
  overflow: hidden;
  /* Hide any overflow */
  display: flex;
  justify-content: center;
  align-items: center;
}

.rounded-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Ensures the image covers the entire container while maintaining aspect ratio */
  border-radius: 10px;
  /* Adjust the border-radius as needed */
}
</style>
