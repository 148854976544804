import axios from "axios";

let token = "f219eb0070a2ffa222461f263769af697a6a42c6fc5abdbebb9a7247ead2f6172e40f2d94a7428ffbfee83c971b6f21122a2fd036fe50a381d80e611b65d22680ec131b20d6a539226b10951031000bd5defb8703b42108fe2bf193d3ae37150e4e133a6356a6109b0a76577b20785f9493b5bd3d7bb4636a9f30d25e5fd49a0";

export default axios.create({
  //  baseURL: "http://10.150.82.108:5000",
  baseURL: "https://cms.tvwan.com.pg",
  headers: {
    "Authorization": `Bearer ${token}`
  }
});
