<template>
    <div>
        <category-layout>
            <section class="about-item-area">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="about-author-content pt-15">
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item">
                                            <a href="/">Home</a>
                                        </li>
                                        <li class="breadcrumb-item"><a href="#">Category</a></li>
                                        <li class="breadcrumb-item active" aria-current="page">
                                            {{ this.newsData.attributes.name }}
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>

                        <!-- Main Stories Section -->
                        <div class="col-lg-8">
                            <div class="about-tab-btn mt-40">
                                <div class="archive-btn">
                                    <ul>
                                        <li>
                                            <span>Category: <span>{{ this.newsData.attributes.name }}</span></span>
                                        </li>
                                    </ul>
                                </div>

                                <div class="about-post-items">
                                    <div class="row">
                                        <!-- Iterating through paginated stories -->
                                        <div v-for="(data, index) in paginatedStories" :key="index" class="col-md-6">
                                            <div class="feature-news-item">
                                                <div class="feature-news-thumb">
                                                    <router-link :to="'/news/' + data.id">
                                                        <img :src="`https://cms.tvwan.com.pg${data.attributes.image.data[0].attributes.formats.small.url}`"
                                                            alt="gallery" />
                                                    </router-link>
                                                </div>
                                                <div class="feature-news-content">
                                                    <p>
                                                        <router-link :to="'/news/' + data.id">
                                                            {{ truncateText(data.attributes.title, 100) }}
                                                        </router-link>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- "See More" Button -->
                                    <div class="col-lg-12 text-center" v-if="!allLoaded">
                                        <div class="bussiness-btn mt-30">
                                            <a class="main-btn main-btn-2" @click="loadMore">See more</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Sidebar for Recent News -->
                        <div class="col-lg-4">
                            <sports-news v-if="this.newsData.attributes.name.toLowerCase() === 'sports'" />
                            <regional-news v-else-if="this.newsData.attributes.name.toLowerCase() === 'regional'" />

                            <div v-else class="all-post-sidebar-style-2 mt-40">
                                <div class="most-view-box">
                                    <div class="section-title">
                                        <h3 class="title">Recent News</h3>
                                    </div>
                                    <div v-for="(data, index) in recentNews.slice(0, 5)" :key="index"
                                        class="international-post-items">
                                        <div class="international-item">
                                            <div class="meta-category">
                                                <span>{{ new Date(data.attributes.createdAt).toLocaleDateString('en-GB')
                                                    }}</span>
                                            </div>
                                            <h5 class="title">
                                                <router-link :to="'/news/' + data.id">{{
                                                    truncateText(data.attributes.title, 50) }}</router-link>
                                            </h5>
                                            <div class="meta-user">
                                                <span><i class="fal fa-user-circle"></i> {{ data.attributes.writtenBy
                                                    }}</span>
                                            </div>
                                            <div class="text-thumb d-flex align-items-center">
                                                <p>{{ truncateText(data.attributes.body, 80) }}</p>
                                                <div class="thumb">
                                                    <img :src="`https://cms.tvwan.com.pg${data.attributes.image.data[0].attributes.formats.thumbnail.url}`"
                                                        alt="business" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </category-layout>
    </div>
</template>

<script>
import CategoryLayout from "./CategoryLayout.vue";
import SportsNews from "../../components/SportsNews/index.vue";
import RegionalNews from "../../components/RegionalNews/index.vue";
import NewsService from "../../service/NewsService";

export default {
    components: { CategoryLayout, SportsNews, RegionalNews },
    data() {
        return {
            paginatedStories: [],  // The currently visible stories
            allStories: [],        // All available stories
            currentPage: 1,        // Current page to display
            pageSize: 8,           // Number of items per page
            allLoaded: false,      // Flag to hide "See More" if all stories are loaded
            newsData: {
                attributes: {
                    name: null,
                },
            },
            recentNews: [],         // Data for recent news in the sidebar
        };
    },
    watch: {
        $route(to, from) {
            if (to.params.name !== from.params.name) {
                this.reloadPage();
            }
        },
    },
    created() {
        this.loadCategoryData(this.$route.params.name);
        this.fetchRecentNews();
    },
    methods: {
        reloadPage() {
            location.reload();
        },
        loadCategoryData(name, page = 1, pageSize = this.pageSize) {
        const capitalizedName = name.charAt(0).toUpperCase() + name.slice(1);
        this.newsData.attributes.name = capitalizedName;

        const newsService = new NewsService();
        newsService
            .getNewsByCategory(name, page, pageSize) // Pass the page and pageSize to the API call
            .then((data) => {
                // console.log('Fetched stories data:', data);
                if (data.length > 0) {
                    // Append new stories to the list
                    this.allStories = this.allStories.concat(data);
                    this.paginatedStories = this.allStories; // Use all stories for display
                } else {
                    // console.log('All stories loaded, no more data to load');
                }
                // console.log('Total stories:', this.allStories.length);
            })
            .catch((error) => {
                console.error("Error fetching news:", error);
            });
    },
        loadMore() {
        // console.log('Load more clicked');
        this.currentPage += 1; // Increment the page count
        // console.log('Current page:', this.currentPage);

        // Call loadCategoryData with the updated page number
        this.loadCategoryData(this.$route.params.name, this.currentPage, this.pageSize);
    },
        fetchRecentNews() {
            const newsService = new NewsService();
            newsService
                .getRecentNews()
                .then((data) => {
                    this.recentNews = data;
                })
                .catch((error) => {
                    console.error("Error fetching recent news:", error);
                });
        },
        truncateText(text, maxLength) {
            return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
        },
    },
};
</script>

<style scoped>
/* Add your styles here */
</style>