<template>
  <section class="contact-area pb-90">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="contact-box mt-4">
            <form @submit.prevent="submitForm">
              <h3 class="title">
                Complaint Form <br />
                Fill out the form.
              </h3>
              <div class="row">
                <div class="col-md-6">
                  <div class="input-box">
                    <input type="text" v-model="formData.name" placeholder="Full name" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-box">
                    <input type="text" v-model="formData.subject" placeholder="Subject" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-box">
                    <input type="email" v-model="formData.email" placeholder="Email address" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-box">
                    <input type="number" v-model="formData.phone" placeholder="Phone number" />
                  </div>
                </div>
                <!-- <div class="col-lg-12">
                                    <div class="input-box">
                                        <div class="g-recaptcha" data-sitekey="6Ley_kMpAAAAAElTNPXqoPxNs8nb8mJWfq5N5fvu"></div>
                                    </div>
                                </div> -->
                <div class="col-lg-12">
                  <div class="input-box">
                    <textarea
                      name="#"
                      id="#"
                      cols="30"
                      rows="10"
                      v-model="formData.message"
                      placeholder="Tell us about your message…"
                    ></textarea>
                    <div
                      class="g-recaptcha"
                      data-sitekey="6Ley_kMpAAAAAElTNPXqoPxNs8nb8mJWfq5N5fvu"
                    ></div>
                    <button class="main-btn">SEND MESSAGE</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// import FromCount from "../Utility/FollowCount/index";
// import SignupStyleOne from "../Utility/Signup/SignupStyleOne.vue";
// import Complaint from "../Utility/Cards/ComplaintsCard.vue";
import complaintService from "../../service/complaintService";

export default {
  //components: { FromCount, SignupStyleOne, Complaint },
  data() {
    return {
        formData: {
            name: "",
            subject: "",
            email: "",
            phone: "",
            message: ""
        },
    };
  },
  methods: {
    async submitForm(e) {
      e.preventDefault();

      try {
        const result = await complaintService.postComplaintData(this.formData);

        console.log("Form Submitted Successfully: ", result);
      } catch (error) {
        console.error("Error submitting form in component:", error);
      }
    },
  },
};
</script>

<style></style>
