<template>
  <!--====== OFFCANVAS MENU PART START ======-->
  <div>
    <div class="off_canvars_overlay" :class="[sidebar ? 'active' : '']" @click.prevent="hideSidebar"></div>
    <div class="offcanvas_menu">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="offcanvas_menu_wrapper" :class="[sidebar ? 'active' : '']"
              style="overflow-y: scroll; overflow-x: hidden">
              <div class="canvas_close">
                <a href="javascript:void(0)" @click="hideSidebar"><i class="fa fa-times"></i></a>
              </div>
              <div class="offcanvas-brand text-center mb-40">
                <img :src="logo" alt="logo" width=auto height="44" />
              </div>
              <!-- <div class="offcanvas-social">
                <ul class="text-center list-unstyled">
                  <li>
                    <a href="/login"><i class="fal fa-user-circle"></i></a>
                  </li>
                  <li>
                    <a v-if="showInstallButton" @click="installApp">
                      <i class="fal fa-download"></i>
                    </a>
                  </li>
                </ul>
              </div> -->
              <div id="menu" :style="heightPro" class="text-left">
                <ul class="offcanvas_main_menu list-unstyled">
                  <li v-for="(item, index) in menuItems" :key="index"
                    :class="['menu-item-has-children', { 'menu-open': menuOpen === item.linkText }]">
                    <span v-if="item.submenu && item.submenu.length > 0 && nasted" class="menu-expand"
                      @click="toggleMenu(item.linkText)">
                      <i class="fa fa-angle-down"></i>
                    </span>
                    <router-link v-if="item.link" :to="item.link" style="text-transform: capitalize">
                      {{ item.linkText }}
                    </router-link>
                    <a v-else href="#" @click.prevent="toggleMenu(item.linkText)">
                      {{ item.linkText }}
                    </a>
                    <ul v-if="item.submenu && nasted" :id="item.linkText" class="sidebar-sub-menu list-unstyled ml-3"
                      :class="{ 'expend_menu_item': menuOpen === item.linkText }">
                      <li v-for="(subItem, i) in item.submenu" :key="i">
                        <router-link :to="subItem.link ? subItem.link : '#'">
                          {{ subItem.linkText }}
                        </router-link>
                        <ul v-if="subItem.child">
                          <li v-for="(thirdMenu, j) in subItem.third_menu" :key="j">
                            <router-link :to="thirdMenu.link ? thirdMenu.link : '#'">
                              {{ thirdMenu.linkText }}
                            </router-link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div class="offcanvas-social">
                <ul class="text-center list-unstyled">
                  <!-- <li>
                    <a href="/login"><i class="fal fa-user-circle"></i></a>
                  </li> -->
                  <li>
                    <a v-if="showInstallButton" @click="installApp">
                      <i class="fal fa-download"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="offcanvas-social">
                <ul>
                  <li><a href="https://www.facebook.com/tvwanonline24"><i class="fab fa-facebook-f"></i></a></li>
                  <li><a href="https://x.com/tvwanonline"><i class="fab fa-twitter"></i></a></li>
                  <li><a href="https://www.youtube.com/@TVWANOnline"><i class="fab fa-youtube"></i></a></li>
                  <li><a href="https://www.instagram.com/tvwan_online"><i class="fab fa-instagram"></i></a></li>
                </ul>
              </div>
              <div class="footer-widget-info">
                <ul>
                  <!-- <li>
                    <a href="#"><i class="fal fa-envelope"></i> tvwannewsroom@digicelpacific.com</a>
                  </li> -->
                  <li>
                    <a href="#"><i class="fal fa-phone"></i> {{ data.contact }}</a>
                  </li>
                  <li>
                    <a href="#"><i class="fal fa-map-marker-alt"></i>{{ data.location }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--====== OFFCANVAS MENU PART ENDS ======-->
</template>

<script>
import Navs from "../Data/NavItems.json";
import Contact from '../../service/Contact';
import AssetService from "../../service/AssetService";

export default {
  props: {
    sidebar: {
      type: Boolean,
      required: true,
    },
    nasted: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      menuOpen: null,
      subMenuHeight: null,
      menuItems: Navs.data,
      data: {
        location: null,
        contact: null,
        email: null,
        logo: ''
      },
      showInstallButton: false,
      deferredPrompt: null
    };
  },
  computed: {
    heightPro() {
      return {
        "--height": this.subMenuHeight,
      };
    },
  },
  methods: {
    hideSidebar(e) {
      this.$emit("toggleSidebar", e);
    },
    show(value) {
      if (value !== this.menuOpen) {
        this.menuOpen = value;
      } else {
        this.menuOpen = null;
      }
      const getListItem = document.querySelectorAll(`#${value} li`).length;
      this.subMenuHeight = 43 * getListItem + "px";
    },
    async fetchLogo() {
      const assetService = new AssetService();
      const prefix = "https://cms.tvwan.com.pg";

      await assetService.getLogo()
        .then((data) => {
          const logo = prefix + data.attributes.url
          this.logo = logo;
          // console.log(this.logo)

        })
        .catch((error) => {
          console.error("Error fetching logo:", error);
        });
    },
    async fetchContactUsData() {
      try {
        const result = await Contact.getContactData();
        this.data.location = result.data.data.attributes.Location;
        this.data.contact = result.data.data.attributes.Contact;
        this.data.email = result.data.data.attributes.Email;
      } catch (error) {
        console.error('Error fetching Contact us data component', error);
      }
    },
    toggleMenu(linkText) {
      if (this.menuOpen === linkText) {
        this.menuOpen = null;
      } else {
        this.menuOpen = linkText;
        this.$nextTick(() => {
          const getListItem = document.querySelectorAll(`#${linkText} li`).length;
          this.subMenuHeight = 43 * getListItem + "px";
        });
      }
    },
    onBeforeInstallPrompt(event) {
      // Prevent the default prompt
      event.preventDefault();
      // Store the event to use later
      this.deferredPrompt = event;
      // Show the install button
      this.showInstallButton = true;
    },
    installApp() {
      if (this.deferredPrompt) {
        // Show the install prompt
        this.deferredPrompt.prompt();
        // Wait for the user to respond to the prompt
        this.deferredPrompt.userChoice.then(choiceResult => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the install prompt');
            // Optionally, you can track the installation
          } else {
            console.log('User dismissed the install prompt');
          }
          // Reset deferredPrompt
          this.deferredPrompt = null;
          // Hide the install button
          this.showInstallButton = false;
        });
      }
    }
  },
  created() {
    this.fetchContactUsData();
    this.fetchLogo();
  },
  mounted() {
    window.addEventListener('beforeinstallprompt', this.onBeforeInstallPrompt);
  },
  beforeDestroy() {
    // Clean up by removing event listener
    window.removeEventListener('beforeinstallprompt', this.onBeforeInstallPrompt);
  }
};

</script>

<style>
.sidebar-sub-menu {
  padding-left: 20px;
  overflow: hidden;
  height: 0px;
}

.sidebar-sub-menu {
  transition: all linear 0.65s;
}

.sidebar-sub-menu.expend_menu_item {
  transition: all linear 0.65s;
}

.expend_menu_item.sidebar-sub-menu {
  height: var(--height);
}
</style>
