<template>
  <section class="post-footer-style-2-area pb-100">
    <div class="container custom-container">
      <div class="row">
        <div class="col-lg-8">
          <div class="mt-40">
            <b-carousel id="my-carousel" v-model="slide" :interval="4000">
              <b-carousel-slide
                v-for="(slideItem, index) in slides"
                :key="index"
                :img-src="slideItem.src"
                class="carousel-slide"
              >
                <div
                  class="overlay"
                  @click="goToLink(slideItem.link)"
                  style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 10; cursor: pointer;"
                ></div>
              </b-carousel-slide>
            </b-carousel>
          </div>
          <div class="row">
            <!-- <signup-style-one /> -->
            <!-- <div class="post-sports-box mt-40">
                <div class="section-title">
                  <h3 class="title">Sports</h3>
                </div>
                <div class="post-sports-item">
                  <div class="post-sports-thumb">
                    <img :src="require(`@/assets/images/${sports[0].image}`)" alt="" />
                  </div>
                  <div class="post-sports-content">
                    <h3 class="title">
                      <router-link to="/posts/postOne">{{
                        sports[0].title
                      }}</router-link>
                    </h3>
                    <p>
                      {{ sports[0].article }}
                    </p>
                    <ul>
                      <li>
                        <a href="#"><i class="far fa-eye"></i> 53,346</a>
                      </li>
                      <li>
                        <a href="#"><i class="far fa-heart"></i> 53,346</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="post_gallery_items">
                  <div v-for="(data, index) in sports.slice(1, 6)" :key="index" class="post-gallery-style-2">
                    <div class="post-gallery-thumb">
                      <img :src="require(`@/assets/images/gallery-post/${data.image}`)
                        " alt="gallery" />
                    </div>
                    <div class="post-gallery-content">
                      <h5 class="title">
                        <router-link to="/posts/postOne">{{
                          data.title
                        }}</router-link>
                      </h5>
                      <ul>
                        <li>
                          <a href="#"><i class="far fa-eye"></i> 53,346</a>
                        </li>
                        <li>
                          <a href="#"><i class="far fa-heart"></i> 53,346</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="post-btn pt-20">
                  <a class="main-btn main-btn-2" href="#">See More</a>
                </div>
              </div> -->
            <div class="col-lg-6 col-md-6">
              <div class="finance-box mt-40">
                <div class="section-title">
                  <h3 class="title">Sports</h3>
                </div>
                <div class="finance-items">
                  <template v-for="(data, index) in sportsNews.slice(0, 1)">
                    <!-- <template v-for="(data, index) in otherfinance.slice(0, 1)">
                    <divide-card stype="col-style-2" :key="index" :datas="data" />
                  </template> -->
                    <div :key="index" class="finance-item">
                      <div class="finance-thumb">
                        <!-- <img :src="require(`@/assets/images/finance/${data.image}`)" alt="finance" /> -->
                        <router-link :to="'/news/' + data.id">
                          <img
                            :src="`https://cms.tvwan.com.pg${data.attributes.image.data[0].attributes.formats.small.url}`"
                            alt="business" />
                        </router-link>
                        <!-- <div class="finance-date">
                          <span>{{ datas.category.name }}</span>
                        </div> -->
                      </div>
                      <div class="finance-content">
                        <h3 class="title">
                          <!-- <router-link to="/posts/postOne">{{ data.attributes.title }}</router-link> -->
                          <router-link :to="'/news/' + (data.id)">{{ truncateText(data.attributes.title)
                            }}</router-link>
                        </h3>
                        <p class="line-clumb">
                          {{ data.attributes.body }}
                        </p>
                        <!-- <ul>
                          <li>
                            <a href="#"><i class="far fa-eye"></i> 53,346</a>
                          </li>
                          <li>
                            <a href="#"><i class="far fa-heart"></i> 53,346</a>
                          </li>
                          <li>
                            <a href="#"><i class="fas fa-share"></i> 53,346</a>
                          </li>
                        </ul> -->
                      </div>
                    </div>
                  </template>
                </div>
                <div class="finance-btn text-center mt-10">
                  <a class="main-btn main-btn-2" href="/category/sports/">See more</a>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="finance-box mt-40">
                <div class="section-title">
                  <h3 class="title">Community</h3>
                </div>
                <div class="finance-items">
                  <template v-for="(data, index) in communityNews.slice(0, 1)">
                    <!-- <template v-for="(data, index) in otherfinance.slice(0, 1)">
                    <divide-card stype="col-style-2" :key="index" :datas="data" />
                  </template> -->
                    <div :key="index" class="finance-item">
                      <div class="finance-thumb">
                        <!-- <img :src="require(`@/assets/images/finance/${data.image}`)" alt="finance" /> -->
                        <router-link :to="'/news/' + data.id">
                          <img
                            :src="`https://cms.tvwan.com.pg${data.attributes.image.data[0].attributes.formats.small.url}`"
                            alt="business" />
                        </router-link>
                        <!-- <div class="finance-date">
                          <span>{{ datas.category.name }}</span>
                        </div> -->
                      </div>
                      <div class="finance-content">
                        <h3 class="title">
                          <!-- <router-link to="/posts/postOne">{{ data.attributes.title }}</router-link> -->
                          <router-link :to="'/news/' + (data.id)">{{ truncateText(data.attributes.title, 80)
                            }}</router-link>
                        </h3>
                        <p class="line-clumb">
                          {{ data.attributes.body }}
                        </p>
                        <!-- <ul>
                          <li>
                            <a href="#"><i class="far fa-eye"></i> 53,346</a>
                          </li>
                          <li>
                            <a href="#"><i class="far fa-heart"></i> 53,346</a>
                          </li>
                          <li>
                            <a href="#"><i class="fas fa-share"></i> 53,346</a>
                          </li>
                        </ul> -->
                      </div>
                    </div>
                  </template>
                </div>
                <div class="finance-btn text-center mt-10">
                  <a class="main-btn main-btn-2" href="/category/community/">See more</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="ad mt-40">
            <b-carousel id="my-carousel2" v-model="slide2" :interval="4000">
              <b-carousel-slide
                v-for="(slideItem, index) in slides2"
                :key="index"
                :img-src="slideItem.src"
                class="carousel-slide"
              >
                <div
                  class="overlay"
                  @click="goToLink(slideItem.link)"
                  style="top: 0; left: 0; width: 300px; height: 250px; z-index: 10; cursor: pointer;"
                ></div>
              </b-carousel-slide>
            </b-carousel>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// import DivideCard from "../Utility/Cards/DivideCard.vue";
// import SignupStyleOne from "../Utility/Signup/SignupStyleOne.vue";

import NewsService from "../../service/NewsService";
import AdService from "../../service/AdvertisementService";

export default {
  components: {},
  props: {
    sports: {
      type: Array,
    },
    otherfinance: {
      type: Array,
    },
  },
  data: () => ({
    sportsNews: {
      attributes: {
        thumbnail: null,
      }
    },
    communityNews: {
      attributes: {
        thumbnail: null,
      }
    },
    slide: 0,
    slide2: 0,
    slides: [],
    slides2: []
  }),
  methods: {
    goToLink(link) {
      window.location.href = link;
    },
    async fetchSportsNews() {
      const newsService = new NewsService();
      await newsService.getSportsNews()
        .then((data) => {
          this.sportsNews = data;
        })
        .catch((error) => {
          console.error("Error fetching news:", error);
        });
    },
    async fetchCommunityNews() {
      const newsService = new NewsService();
      await newsService.getCommunityNews()
        .then((data) => {
          this.communityNews = data;
        })
        .catch((error) => {
          console.error("Error fetching news:", error);
        });
    },
    truncateText(text, maxLength) {
      if (text.length > maxLength) {
        return text.slice(0, maxLength) + "...";
      }
      return text;
    },
    async fetchBanners() {
      const adService = new AdService();
      const prefix = "https://cms.tvwan.com.pg";

      let url

      await adService.getAdvertismentsURL()
        .then((data) => {
          url = data
        })
        .catch((error) => {
          console.error("Error fetching url:", error);
        });

      await adService.getAdvertismentsBody()
        .then((data) => {
          const slides = data.map(entry => ({ src: prefix + entry.attributes.url, link: url }));
          this.slides = slides;
        })
        .catch((error) => {
          console.error("Error fetching body:", error);
        });
    },
    async fetchBanners2() {
      const adService = new AdService();
      const prefix = "https://cms.tvwan.com.pg";

      let url

      await adService.getAdvertismentsURL()
        .then((data) => {
          url = data
        })
        .catch((error) => {
          console.error("Error fetching url:", error);
        });

      await adService.getAdvertismentsBody2()
        .then((data) => {
          const slides = data.map(entry => ({ src: prefix + entry.attributes.url, link: url }));
          this.slides2 = slides;
        })
        .catch((error) => {
          console.error("Error fetching body:", error);
        });
    },
  },
  mounted() {
    this.fetchSportsNews();
    this.fetchCommunityNews();
    this.fetchBanners();
    this.fetchBanners2();
  }
};


</script>

<style>
</style>
