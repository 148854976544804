<template>
  <section class="contact-area pb-90">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="contact-box">
            <b-form @submit.prevent="submit2" v-if="show">
              <h3 class="title"> Let’s work together! <br> Fill out the form. </h3>
              <b-form-group id="input-group-1" label="Email address:" label-for="input-1"
                description="We'll never share your email with anyone else.">
                <b-form-input id="input-1" v-model="formData.email" type="email" placeholder="Enter email"
                  required></b-form-input>
              </b-form-group>
              <b-form-group id="input-group-2" label="Your Name:" label-for="input-2">
                <b-form-input id="input-2" v-model="formData.fullName" placeholder="Enter name" required></b-form-input>
              </b-form-group>
              <b-form-group id="input-group-3" label="Subject:" label-for="input-3">
                <b-form-input id="input-3" v-model="formData.subject" placeholder="Enter Subject"
                  required></b-form-input>
              </b-form-group>
              <b-form-group id="input-group-4" label="Phone Number:" label-for="input-4">
                <b-form-input id="input-4" v-model="formData.phone" placeholder="Enter Phone Number"
                  required></b-form-input>
              </b-form-group>
              <b-form-group id="input-group-5" label="Message:" label-for="input-5">
                <b-textarea id="input-5" v-model="formData.message" placeholder="Enter your message" rows="3"
                  required></b-textarea>
              </b-form-group>
              <div class="text-center">
                <div id="recaptcha" class="g-recaptcha mb-3" data-sitekey="6LfSdgEqAAAAAKMDkOytHOK62v65z41oQNaz1biA">
                </div>
              </div>
              <b-button type="submit" variant="primary" class="mr-2">Submit</b-button>
              <b-button @click="onReset" type="button" variant="danger">Reset</b-button>
            </b-form>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="contact-sidebar">
            <!-- <FromCount /> -->
            <!-- <signup-style-one /> -->
            <!-- <Complaint /> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script async src="https://www.google.com/recaptcha/api.js"></script>
<script>
import ContactService from "../../service/Contact";
import VueSweetalert2 from "vue-sweetalert2";
import Vue from "vue";
import "sweetalert2/dist/sweetalert2.min.css";

Vue.use(VueSweetalert2);

export default {
  data() {
    return {
      formData: {
        fullName: "",
        email: "",
        subject: "",
        phone: "",
        message: "",
      },
      show: true,
      recaptchaResponse: "",
    };
  },
  methods: {
    async submit2() {
      if (this.recaptchaResponse) {
        try {
          const result = await ContactService.postContactData({ ...this.formData, recaptcha: this.recaptchaResponse });
          this.$swal({
            icon: "success",
            title: "Success!",
            text: "Form submitted successfully",
          });
          this.onReset();
        } catch (error) {
          console.error("Error submitting form:", error);
          this.$swal({
            icon: "error",
            title: "Error",
            text: "There was a problem submitting the form. Please try again.",
          });
        }
      } else {
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Please verify you are not a robot.",
        });
      }
    },
    onReset() {
      this.formData = {
        fullName: "",
        email: "",
        subject: "",
        phone: "",
        message: "",
      };
      this.recaptchaResponse = "";
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
      grecaptcha.reset(); // Reset reCAPTCHA
    },
    onRecaptchaSuccess(recaptchaToken) {
      console.log("reCAPTCHA token received:", recaptchaToken);
      this.recaptchaResponse = recaptchaToken;
    },
  },
  mounted() {
    window.onRecaptchaSuccess = this.onRecaptchaSuccess; // Define a global function to handle reCAPTCHA success
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit";
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);

    window.onloadCallback = () => {
      grecaptcha.render("recaptcha", {
        sitekey: "6LfSdgEqAAAAAKMDkOytHOK62v65z41oQNaz1biA",
        callback: this.onRecaptchaSuccess,
      });
    };
  },
};
</script>

<style>
/* Add your styles here */
</style>