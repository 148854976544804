import Vue from 'vue';
import Vuex from 'vuex';
import { auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        user: null,
        authInitialized: false,
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
        setAuthInitialized(state, initialized) {
            state.authInitialized = initialized;
        },
    },
    actions: {
        fetchUser({ commit }) {
            onAuthStateChanged(auth, user => {
                commit('setUser', user);
                commit('setAuthInitialized', true);
            });
        },
    },
});
