<template>
  <div>
    <drawer @toggleSidebar="toggleSidebar" :sidebar="sidebar" />
    <Header @toggleSidebar="toggleSidebar" />
    <!--====== POST LAYOUT 1 PART START ======-->

    <section class="post-layout-1-area pb-80">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="about-author-content">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/">Home</a></li>
                  <li class="breadcrumb-item"><a href @click.prevent="$router.go(-1)">{{
                    newsData[0].attributes.category.data.attributes.name }}</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {{ truncateText(newsData[0].attributes.title, 30) }}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="post-layout-top-content">
              <div class="
                  post-categories
                  d-flex
                  justify-content-between
                  align-content-center
                ">
                <div class="categories-item">
                  <span>{{ newsData[0].attributes.category.data.attributes.name }}</span>
                </div>
                <!-- <div class="categories-share">
                  <ul>
                    <li><i class="fas fa-comment"></i>45020</li>
                    <li><i class="fas fa-fire"></i>45020</li>
                  </ul>
                </div> -->
              </div>
              <div class="post-content">
                <h3 class="title">
                  {{ newsData[0].attributes.title }}
                </h3>
                <!-- <p>
                  The property, complete with 30-seat screening from room, a
                  100-seat amphitheater and a swimming pond with sandy shower…
                </p> -->
                <div class="thumb">
                  <!-- <img src="@/assets/images/post-layout.png" alt="" /> -->
                  <img :src="this.newsData.smallImg" alt="" />
                </div>
                <!-- <div style="text-align: center;">
                  <h6 style="font-style: italic;">{{ newsData[0].attributes.title }}</h6>
                </div> -->
                <div id="app" style="text-align: left;">
                  <h6 style="font-style: italic; font-size: 0.8em; opacity: 0.8;">
                    <span v-if="newsData[0].attributes.caption === null">{{ "" }}</span>
                    <span v-else>{{ newsData[0].attributes.caption }}</span>
                  </h6>
                </div>
                
              </div>
              <div class="post-author">
                <div class="author-info">
                  <div class="thumb">
                    <img :src="author" alt="author" />
                  </div>
                  <h5 class="title">{{ newsData[0].attributes.writtenBy }}</h5>
                  <ul>
                    <li>{{ new Date(newsData[0].attributes.createdAt).toLocaleDateString('en-GB') }}</li>
                    <span><br></span>
                  </ul>
                  
                </div>
                <div class="author-social">
                  <ul>
                    <li>
                      <a href="https://www.facebook.com/" target="_blank"><i class="fab fa-facebook-f"></i></a>
                    </li>
                    <li>
                      <a href="#"><i class="fab fa-twitter"></i></a>
                    </li>
                    <!-- <li>
                      <a href="#"><i class="fab fa-youtube"></i></a>
                    </li> -->
                    <li>
                      <a href="#"><i class="fab fa-instagram"></i></a>
                    </li>
                    <!-- <li>
                      <a href="#"><i class="fal fa-heart"></i></a>
                    </li> -->
                    <li>
                      <a href="#"><i class="fal fa-share"></i></a>
                    </li>
                    <!-- <li>
                      <a href="#"><i class="fas fa-ellipsis-v"></i></a>
                    </li> -->
                  </ul>
                </div>
              </div>
              <div class="post-text mt-30">
                <p v-if="newsData[0].attributes.body" v-html="newsData[0].attributes.body.replace(/\n/g, '<br>')"></p>
              </div>
              <div v-if="videoSrc" class="post-text mt-30">
                <iframe class="responsive-iframe" :src="videoSrc" frameborder="0" allowfullscreen></iframe>
              </div>
              <div class="post-tags">
                <ul>
                  <li>
                    <a href="#"><i class="fas fa-tag"></i> Tags</a>
                  </li>
                  <!-- <li><a href="#">Health</a></li> -->
                  <li><a href="#">{{ newsData[0].attributes.category.data.attributes.name }}</a></li>

                  <!-- <li><a href="#">Corona</a></li> -->
                </ul>
              </div>
              <!-- <div class="post-reader-text pt-50">
                <div class="row">
                  <div class="col-md-6">
                    <div class="post-reader-prev">
                      <span>PREVIOUS NEWS</span>
                      <h4 class="title">
                        <a href="#">Kushner puts himself in middle of white house’s
                          chaotic coronavirus response.</a>
                      </h4>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="post-reader-prev">
                      <span>NEXT NEWS</span>
                      <h4 class="title">
                        <a href="#">C.I.A. Hunts for authentic virus totals in china,
                          dismissing government tallies</a>
                      </h4>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
          <!-- <style-one :datas="Posts" /> -->
          <div class="col-lg-4">
            <div class="all-post-sidebar-style-2 mt-40">
              <div class="most-view-box">
                <div class="section-title">
                  <h3 class="title">Recent News</h3>
                </div>
                <template v-for="(data, index) in recentNews.slice(0, 5)">
                  <div :key="index" class="international-post-items">
                    <div class="international-item">
                      <div class="meta-category">
                        <!-- <span>Source: {{ data.source.name }}</span> -->
                        <span>{{ new Date(data.attributes.createdAt).toLocaleDateString('en-GB')
                          }}</span>
                      </div>
                      <h5 class="title">
                        <!-- <router-link to="/posts/postOne">{{ data.title }}</router-link> -->
                        <router-link :to="'/news/' + (data.id)">{{
                          truncateText(data.attributes.title, 50)
                          }}</router-link>
                      </h5>
                      <div class="meta-user">
                        <span><i class="fal fa-user-circle"></i> {{ data.attributes.writtenBy
                          }}</span>
                      </div>
                      <div class="text-thumb d-flex align-items-center">
                        <p>
                          {{ truncateText(data.attributes.body, 80) }}
                        </p>
                        <div class="thumb">
                          <img
                            :src="`https://cms.tvwan.com.pg${data.attributes.image.data[0].attributes.formats.thumbnail.url}`"
                            alt="business" />
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
              <!-- <div class="ad mt-40">
                <a href="/"><img src="https://cms.tvwan.com.pg/uploads/8196285962274064809_b44ca665d9.png"
                    alt="" /></a>
              </div> -->
              <!-- <div class="post-form-area">
                <div class="container">
                  <div class="row justify-content-center">
                    <div class="col-lg-12">
                      <div class="section-title">
                        <h3 class="title">Leave an opinion</h3>
                      </div>
                      <div class="post-form-box">
                        <form action="#">
                          <div class="row">
                            <div class="col-lg-6">
                              <div class="input-box">
                                <input type="text" placeholder="Full name" />
                              </div>
                            </div>
                            <div class="col-lg-6">
                              <div class="input-box">
                                <input type="text" placeholder="Email address" />
                              </div>
                            </div>
                            <div class="col-lg-12">
                              <div class="input-box">
                                <textarea name="#" id="#" cols="30" rows="10"
                                  placeholder="Tell us about your opinion…"></textarea>
                                <button class="main-btn" type="button">
                                  POST OPINION
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
              <!-- <section class="post-comments-area pb-100">
                <div class="container">
                  <div class="row justify-content-center">
                    <div class="col-lg-12">
                      <div class="section-title">
                        <h3 class="title">Post Comments</h3>
                      </div>
                      <div class="post-comments-list">
                        <div class="post-comments-item">
                          <div class="thumb">
                            <img src="@/assets/images/comments-1.png" alt="comments" />
                          </div>
                          <div class="post">
                            <a href="#">Reply</a>
                            <h5 class="title">Rafiqul islam</h5>
                            <p>
                              We’ve invested every aspect of how we serve our users over
                              the past Pellentesque rutrum ante in nulla suscipit, vel
                              posuere leo tristique.
                            </p>
                          </div>
                        </div>
                        <div class="post-comments-item">
                          <div class="thumb">
                            <img src="@/assets/images/comments-2.png" alt="comments" />
                          </div>
                          <div class="post">
                            <a href="#">Reply</a>
                            <h5 class="title">Rafiqul islam</h5>
                            <p>
                              We’ve invested every aspect of how we serve our users over
                              the past Pellentesque rutrum ante in nulla suscipit, vel
                              posuere leo tristique.
                            </p>
                          </div>
                        </div>
                        <div class="post-comments-item ml-30">
                          <div class="thumb">
                            <img src="@/assets/images/comments-3.png" alt="comments" />
                          </div>
                          <div class="post">
                            <a href="#">Reply</a>
                            <h5 class="title">Rafiqul islam</h5>
                            <p>
                              We’ve invested every aspect of how we serve our users over
                              the past Pellentesque rutrum ante in nulla suscipit, vel
                              posuere leo tristique.
                            </p>
                          </div>
                        </div>
                        <div class="post-comments-item">
                          <div class="thumb">
                            <img src="@/assets/images/comments-4.png" alt="comments" />
                          </div>
                          <div class="post">
                            <a href="#">Reply</a>
                            <h5 class="title">Rafiqul islam</h5>
                            <p>
                              We’ve invested every aspect of how we serve our users over
                              the past Pellentesque rutrum ante in nulla suscipit, vel
                              posuere leo tristique.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="post-load-btn">
                        <a class="main-btn" href="#">LOAD MORE</a>
                      </div>
                    </div>
                  </div>
                </div>
              </section> -->
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--====== POST LAYOUT 1 PART ENDS ======-->

    <!--====== LATEST NEWS PART START ======-->

    <!-- <our-latest-news :Posts="Posts" /> -->

    <!--====== LATEST NEWS PART ENDS ======-->

    <!--====== POST FORM PART START ======-->


    <!--====== POST FORM PART ENDS ======-->

    <!--====== POST COMMENTS PART START ======-->



    <!--====== POST COMMENTS PART ENDS ======-->

    <!--====== ADD PART ENDS ======-->

    <div class="add-area text-center">
      <!-- <a href="#"><img src="https://cms.tvwan.com.pg/uploads/728x90_6347b2ada5.png" alt="" /></a> -->
    </div>

    <!--====== ADD PART ENDS ======-->

    <footer-one />
    <div class="go-top-area">
      <div class="go-top-wrap">
        <div class="go-top-btn-wrap">
          <a href="#" class="go-top go-top-btn d-block">
            <i class="fa fa-angle-double-up"></i>
            <i class="fa fa-angle-double-up"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../../Utility/HeaderTwo/index";
// import StyleOne from "../../Utility/Sidebar/StyleOne.vue";
import Posts from "../../Data/NewsRowCard";
import FooterOne from "../../HomeTwo/Footer.vue";
// import OurLatestNews from "../../Utility/Common/OurLatestNews.vue";
import Drawer from "../../Mobile/Drawer.vue";
import NewsService from "../../../service/NewsService";
import AssetService from "../../../service/AssetService";


export default {
  components: { Header, FooterOne, Drawer },
  data() {
    return {
      Posts: Posts.data,
      sidebar: false,
      videoSrc: null,
      newsData: {
        attributes: {
          createdAt: null,
          smallImg: null
        }
      },
      filters: {
        nl2br: function (value) {
          if (!value) return '';
          return value.replace(/\n/g, '<br>');
        }
      },
      recentNews: {
        attributes: {
          // thumbnail: null
        }
      },
      author: ''
    }
  },
  methods: {
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    reloadPage() {
      location.reload();
    },
    topToBottom() {
      const result = document.querySelector(".go-top");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.classList.add("active");
      } else {
        result.classList.remove("active");
      }
    },
    truncateText(text, maxLength) {
      if (text.length > maxLength) {
        return text.slice(0, maxLength) + "...";
      }
      return text;
    },
    async getRecentNews() {
      const newsService = new NewsService();
      await newsService.getRecentNews()
        .then((data) => {
          this.recentNews = data;
          // console.log(this.recentNews)
        })
        .catch((error) => {
          console.error("Error fetching news:", error);
        });
    },
    async fetchAuthor() {
      const assetService = new AssetService();
      const prefix = "https://cms.tvwan.com.pg";

      await assetService.getAuthor()
        .then((data) => {
          const author = prefix + data.attributes.url
          this.author = author;
          // console.log(this.author)

        })
        .catch((error) => {
          console.error("Error fetching logo:", error);
        });
    },
  },
  created: function () {
    document.addEventListener("scroll", this.topToBottom);

    let id = this.$route.params.id;
    // console.log(id)

    //service call to fetch newsdata of specif id
    const newsService = new NewsService();
    newsService.getNewsById(id)
      .then((data) => {
        this.newsData = data;
        // console.log(data[0].attributes.caption)

        if (data[0].attributes.videolink == null) {
          // console.log("Null")
        }
        else {
          this.videoSrc = `https://www.youtube.com/embed/${data[0].attributes.videolink}`;
        }

        // console.log(data[0].attributes.videolink)
        // this.newsData.createdAt = data.attributes.createdAt
        this.newsData.smallImg = 'https://cms.tvwan.com.pg' + data[0].attributes.image.data[0].attributes.formats.medium.url
      })
      .catch((error) => {
        console.error("Error fetching news:", error);
      });
  },
  mounted() {
    this.getRecentNews();
    this.fetchAuthor();
  },
  watch: {
    $route(to, from) {
      // Reload the page whenever the route parameter 'name' changes
      if (to.params.id !== from.params.id) {
        this.reloadPage();
      }
    },
  },

};
</script>

<style>
.responsive-iframe {
  width: 560px;
  height: 315px;
}

@media (max-width: 767px) {
  .responsive-iframe {
    width: 300px;
    height: 200px;
    /* Maintain the 16:9 aspect ratio */
  }
}
</style>
