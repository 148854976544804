import http from "../http-common";
import axios from "axios";

// let news_api_key = af5a3aad4f8c44deafe2933534afc882;

export default class NewsService {
    data() {
        return {
            data: null,
        };
    }

    async getNews() {//used
        return await http.get(`/api/newsposts/?filters[isFeatured][$eq]=true&fields[0]=title&sort=createdAt:DESC&pagination[pageSize]=5&pagination[page]=1`)
            .then(result => {
                // console.log(result.data); // This will log the response data
                return result.data; // Return the data to be used in the component
            })
            .catch(error => {
                console.error("Error fetching news:", error);
                throw error; // Rethrow the error so it can be handled in the component
            });
    }

    async getNewsById(id) {//used
        return await http.get(`/api/newsposts?populate=*&filters[id][$eq]=${id}`)
            .then(result => {
                // console.log(result.data); // This will log the response data
                return result.data.data; // Return the data to be used in the component
            })
            .catch(error => {
                console.error("Error fetching news:", error);
                throw error; // Rethrow the error so it can be handled in the component
            });
    }

    async getVideoNewsById(id) {//used
        return await http.get(`/api/video-posts?populate=*&filters[id][$eq]=${id}`)
            .then(result => {
                // console.log(result.data); // This will log the response data
                return result.data.data; // Return the data to be used in the component
            })
            .catch(error => {
                console.error("Error fetching news:", error);
                throw error; // Rethrow the error so it can be handled in the component
            });
    }

    async getTrendingNews() {//used
        return await http.get(`/api/newsposts/?populate=*&sort=createdAt:DESC&pagination[pageSize]=1&pagination[page]=1`)
            .then(result => {
                // console.log(result.data); // This will log the response data
                const mostRecentNews = result.data.data[0]; // Get the most recent news post from the response
                return mostRecentNews; // Return the most recent news post to be used in the component
            })
            .catch(error => {
                console.error("Error fetching news:", error);
                throw error; // Rethrow the error so it can be handled in the component
            });
    }

    async getRecentNews() {//used
        return await http.get(`api/newsposts/?populate=*&sort=createdAt:DESC&pagination[pageSize]=5`)
            .then(result => {
                // console.log(result.data); // This will log the response data
                const mostRecentNews = result.data.data; // Get the most recent news post from the response
                return mostRecentNews; // Return the most recent news post to be used in the component
            })
            .catch(error => {
                console.error("Error fetching news:", error);
                throw error; // Rethrow the error so it can be handled in the component
            });
    }

    async getFeaturedNews() {//used
        return await http.get(`/api/newsposts/?filters[isFeatured][$eq]=true&fields[0]=title&fields[1]=body&pagination[pageSize]=6&populate=*`)
            .then(result => {
                const mostRecentNews = result.data.data; // Get the most recent news post from the response
                // console.log(mostRecentNews)
                return mostRecentNews; // Return the most recent news post to be used in the component
            })
            .catch(error => {
                console.error("Error fetching news:", error);
                throw error; // Rethrow the error so it can be handled in the component
            });
    }

    async getBusinessNews() {//used
        return await http.get(`api/newsposts?populate=*&sort=createdAt:DESC&filters[category][name][$eq]=Business&pagination[pageSize]=3`)
            .then(result => {
                // console.log(result.data.data); // This will log the response data
                const mostRecentNews = result.data.data; // Get the most recent news post from the response
                return mostRecentNews; // Return the most recent news post to be used in the component
            })
            .catch(error => {
                console.error("Error fetching news:", error);
                throw error; // Rethrow the error so it can be handled in the component
            });
    }

    async getTechnologyNews() {//6
        return await http.get(`api/newsposts?populate=*&filters[category][name][$eq]=Technology&pagination[pageSize]=2`)
            .then(result => {
                // console.log(result.data.data); // This will log the response data
                const mostRecentNews = result.data.data; // Get the most recent news post from the response
                return mostRecentNews; // Return the most recent news post to be used in the component
            })
            .catch(error => {
                console.error("Error fetching news:", error);
                throw error; // Rethrow the error so it can be handled in the component
            });
    }

    // async getWorldNews() {//6
    //     return await http.get(`api/newsposts?populate=*&filters[category][name][$eq]=World News`)
    //         .then(result => {
    //             // console.log(result.data.data); // This will log the response data
    //             const mostRecentNews = result.data.data; // Get the most recent news post from the response
    //             return mostRecentNews; // Return the most recent news post to be used in the component
    //         })
    //         .catch(error => {
    //             console.error("Error fetching news:", error);
    //             throw error; // Rethrow the error so it can be handled in the component
    //         });
    // }

    async getWorldNewsNew() {//used
        return await axios.get(`https://api.worldnewsapi.com/search-news?number=5&offset=0&news-sources=https://www.aljazeera.com/&sort-direction=DESC&sort=publish-time&api-key=dd364306e1eb43e49da6c8fe776fea2c`)
            .then(result => {
                // console.log(result.data.news); // This will log the response data
                const mostRecentNews = result.data.news; // Get the most recent news post from the response
                return mostRecentNews; // Return the most recent news post to be used in the component
            })
            .catch(error => {
                console.error("Error fetching news:", error);
                throw error; // Rethrow the error so it can be handled in the component
            });
    }
    async getFinanceNews() {//used
        return await axios.get(`https://api.worldnewsapi.com/search-news?number=5&offset=0&news-sources=https://www.aljazeera.com/economy/&sort-direction=DESC&sort=publish-time&api-key=dd364306e1eb43e49da6c8fe776fea2c`)
            .then(result => {
                // console.log(result.data.news); // This will log the response data
                const mostRecentNews = result.data.news; // Get the most recent news post from the response
                return mostRecentNews; // Return the most recent news post to be used in the component
            })
            .catch(error => {
                console.error("Error fetching news:", error);
                throw error; // Rethrow the error so it can be handled in the component
            });
    }

    async getRegionalNews() {//used
        return await axios.get(`https://api.worldnewsapi.com/search-news?number=5&offset=0&news-sources=https://www.abc.net.au/pacific&sort-direction=DESC&sort=publish-time&api-key=dd364306e1eb43e49da6c8fe776fea2c`)
            .then(result => {
                // console.log(result.data.news); // This will log the response data
                const mostRecentNews = result.data.news; // Get the most recent news post from the response
                return mostRecentNews; // Return the most recent news post to be used in the component
            })
            .catch(error => {
                console.error("Error fetching news:", error);
                throw error; // Rethrow the error so it can be handled in the component
            });
    }

    async getSportNews() {//used
        return await axios.get(`https://api.worldnewsapi.com/search-news?number=5&offset=0&news-sources=https://www.bbc.com/sport/rugby-league/&sort-direction=DESC&sort=publish-time&api-key=dd364306e1eb43e49da6c8fe776fea2c`)
            .then(result => {
                // console.log(result.data.news); // This will log the response data
                const mostRecentNews = result.data.news; // Get the most recent news post from the response
                return mostRecentNews; // Return the most recent news post to be used in the component
            })
            .catch(error => {
                console.error("Error fetching news:", error);
                throw error; // Rethrow the error so it can be handled in the component
            });
    }

    async extractWorldNews(url) {//used
        return await axios.get(`https://api.worldnewsapi.com/extract-news?url=${url}&analyze=true&api-key=dd364306e1eb43e49da6c8fe776fea2c`)
            .then(result => {
                // console.log(result.data); // This will log the response data
                const mostRecentNews = result.data; // Get the most recent news post from the response
                return mostRecentNews; // Return the most recent news post to be used in the component
            })
            .catch(error => {
                console.error("Error fetching news:", error);
                throw error; // Rethrow the error so it can be handled in the component
            });
    }

    async getSportsNews() {//used
        return await http.get(`api/newsposts?populate=*&sort=createdAt:DESC&filters[category][name][$eq]=Sports&pagination[pageSize]=1`)
            .then(result => {
                // console.log(result.data.data); // This will log the response data
                const mostRecentNews = result.data.data; // Get the most recent news post from the response
                return mostRecentNews; // Return the most recent news post to be used in the component
            })
            .catch(error => {
                console.error("Error fetching news:", error);
                throw error; // Rethrow the error so it can be handled in the component
            });
    }

    async getCommunityNews() {//used
        return await http.get(`api/newsposts?populate=*&sort=createdAt:DESC&filters[category][name][$eq]=Community&pagination[pageSize]=1`)
            .then(result => {
                // console.log(result.data.data); // This will log the response data
                const mostRecentNews = result.data.data; // Get the most recent news post from the response
                return mostRecentNews; // Return the most recent news post to be used in the component
            })
            .catch(error => {
                console.error("Error fetching news:", error);
                throw error; // Rethrow the error so it can be handled in the component
            });
    }

    // async getNewsByCategory(name) {//used
    //     return await http.get(`/api/newsposts?populate=*&filters[category][name][$eq]=${name}&sort=createdAt:DESC&pagination[pageSize]=8`)
    //         .then(result => {
    //             // console.log(result.data.data); // This will log the response data
    //             return result.data.data; // Return the data to be used in the component
    //         })
    //         .catch(error => {
    //             console.error("Error fetching news:", error);
    //             throw error; // Rethrow the error so it can be handled in the component
    //         });
    // }

    async getNewsByCategory(name, page = 1, pageSize = 8) {
        return await http.get(`/api/newsposts?populate=*&filters[category][name][$eq]=${name}&sort=createdAt:DESC&pagination[page]=${page}&pagination[pageSize]=${pageSize}`)
            .then(result => {
                // Log the response data for debugging
                // console.log(result.data.data);
                return result.data.data; // Return the data to be used in the component
            })
            .catch(error => {
                console.error("Error fetching news:", error);
                throw error; // Rethrow the error so it can be handled in the component
            });
    }
    

    // async getVideoNewsByCategory(name) {//used
    //     return await http.get(`/api/video-posts?populate=*&filters[category][name][$eq]=${name}&sort=createdAt:DESC&pagination[pageSize]=8`)
    //         .then(result => {
    //             // console.log(result.data.data); // This will log the response data
    //             return result.data.data; // Return the data to be used in the component
    //         })
    //         .catch(error => {
    //             console.error("Error fetching news:", error);
    //             throw error; // Rethrow the error so it can be handled in the component
    //         });
    // }
    async getVideoNewsByCategory(name, page = 1) {
        return await http.get(`/api/video-posts?populate=*&filters[category][name][$eq]=${name}&sort=createdAt:DESC&pagination[page]=${page}&pagination[pageSize]=8`)
            .then(result => {
                return result.data.data; // Return the data to be used in the component
            })
            .catch(error => {
                console.error("Error fetching news:", error);
                throw error; // Rethrow the error so it can be handled in the component
            });
    }
    
    

    async getVideoNews() {//used
        return await http.get(`api/video-posts/?populate=*&sort=createdAt:DESC&pagination[pageSize]=4`)
            .then(result => {
                // console.log(result.data); // This will log the response data
                const mostRecentNews = result.data.data; // Get the most recent news post from the response
                return mostRecentNews; // Return the most recent news post to be used in the component
            })
            .catch(error => {
                console.error("Error fetching news:", error);
                throw error; // Rethrow the error so it can be handled in the component
            });
    }

    async getLiveNews() {//used
        return await http.get(`api/live-video?populate=*`)
            .then(result => {
                // console.log(result.data); // This will log the response data
                const mostRecentNews = result.data.data; // Get the most recent news post from the response
                return mostRecentNews; // Return the most recent news post to be used in the component
            })
            .catch(error => {
                console.error("Error fetching news:", error);
                throw error; // Rethrow the error so it can be handled in the component
            });
    }

    async checkisLive() {
        return await http.get(`api/event`)
            .then(result => {
                // console.log(result.data); // This will log the response data
                const mostRecentNews = result.data.data; // Get the most recent news post from the response
                return mostRecentNews; // Return the most recent news post to be used in the component
            })
            .catch(error => {
                console.error("Error fetching news:", error);
                throw error; // Rethrow the error so it can be handled in the component
            });
    }
}