<template>
  <div class="header-topbar">
    <div class="container custom-container">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="topbar-trending">
            <span>Trending</span>
            <div class="trending-slider">
              <span @click="prev" class="prev slick-arrow" style="display: block"><i
                  class="fal fa-angle-left"></i></span>
              <slider :settings="settings" ref="carousel">
                <div v-for="(item, index) in newsData.data" :key="index" class="trending-item">
                  <p>{{ truncateText(item.attributes.title, 80) }}</p>
                </div>
              </slider>
              <span @click="next" class="next slick-arrow" style="display: block"><i
                  class="fal fa-angle-right"></i></span>
            </div>
          </div>
        </div>
        <div class="col-lg-2 mb-1">
          <b-button v-if="showInstallButton" variant="primary" @click="installApp"
            style="background: linear-gradient(45deg, #fe402a 0%, #a101ff 100%);">
            Install App
          </b-button>
        </div>
        <div class="col-lg-4">
          <div class="topbar-social d-flex align-items-center">
            <div class="social">
              <ul>
                <li><a href="https://www.facebook.com/tvwanonline24"><i class="fab fa-facebook-f"></i></a></li>
                <li><a href="https://x.com/tvwanonline"><i class="fab fa-twitter"></i></a></li>
                <li><a href="https://www.youtube.com/@TVWANOnline"><i class="fab fa-youtube"></i></a></li>
                <li><a href="https://www.instagram.com/tvwan_online"><i class="fab fa-instagram"></i></a></li>
              </ul>
            </div>
            <!-- <div class="header-menu-search">
              <ul>
                <li>
                  <router-link v-if="hasToken" to="/profile"><i class="fal fa-user-circle"></i></router-link>
                  <router-link v-else to="/login"><i class="fal fa-user-circle"></i></router-link>
                </li>
              </ul>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Slider from "../../Helpers/Slider.vue";
import NewsService from "../../../service/NewsService";

export default {
  components: {
    Slider
  },
  data() {
    return {
      settings: {
        dots: false,
        arrows: false,
        autoplay: true
      },
      newsData: null,
      showInstallButton: false,
      deferredPrompt: null
    };
  },
  methods: {
    next() {
      this.$refs.carousel.next();
    },
    prev() {
      this.$refs.carousel.prev();
    },
    fetchNews() {
      const newsService = new NewsService();
      newsService.getNews()
        .then(data => {
          this.newsData = data;
        })
        .catch(error => {
          console.error("Error fetching news:", error);
        });
    },
    truncateText(text, maxLength) {
      if (text.length > maxLength) {
        return text.slice(0, maxLength) + "...";
      }
      return text;
    },
    onBeforeInstallPrompt(event) {
      // Prevent the default prompt
      event.preventDefault();
      // Store the event to use later
      this.deferredPrompt = event;
      // Show the install button
      this.showInstallButton = true;
    },
    installApp() {
      if (this.deferredPrompt) {
        // Show the install prompt
        this.deferredPrompt.prompt();
        // Wait for the user to respond to the prompt
        this.deferredPrompt.userChoice.then(choiceResult => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the install prompt');
            // Optionally, you can track the installation
          } else {
            console.log('User dismissed the install prompt');
          }
          // Reset deferredPrompt
          this.deferredPrompt = null;
          // Hide the install button
          this.showInstallButton = false;
        });
      }
    }
  },
  mounted() {
    this.fetchNews();

    // Listen for the beforeinstallprompt event
    window.addEventListener('beforeinstallprompt', this.onBeforeInstallPrompt);
  },
  beforeDestroy() {
    // Clean up by removing event listener
    window.removeEventListener('beforeinstallprompt', this.onBeforeInstallPrompt);
  }

};
</script>

<style scoped>
/* Add your scoped styles here */
</style>
