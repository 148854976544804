import http from "../http-common";

// let news_api_key = af5a3aad4f8c44deafe2933534afc882;

export default class AdvertisementService {
    data() {
        return {
            data: null,
        };
    }

    async getAdvertismentsHeader() {//used
        return await http.get(`/api/advertisement?populate=*`)
            .then(result => {
                // console.log(result.data.data.attributes.Header728x90.data); // This will log the response data
                const mostRecentNews = result.data.data.attributes.Header728x90.data; // Get the most recent news post from the response
                return mostRecentNews; // Return the most recent news post to be used in the component
            })
            .catch(error => {
                console.error("Error fetching advertisement:", error);
                throw error; // Rethrow the error so it can be handled in the component
            });
    }

    async getAdvertismentsBody() {//used
        return await http.get(`/api/advertisement?populate=*`)
            .then(result => {
                // console.log(result.data.data.attributes.Header728x90.data); // This will log the response data
                const mostRecentNews = result.data.data.attributes.Body728x90.data; // Get the most recent news post from the response
                return mostRecentNews; // Return the most recent news post to be used in the component
            })
            .catch(error => {
                console.error("Error fetching advertisement:", error);
                throw error; // Rethrow the error so it can be handled in the component
            });
    }

    async getAdvertismentsBody2() {//used
        return await http.get(`/api/advertisement?populate=*`)
            .then(result => {
                // console.log(result.data.data.attributes.Header728x90.data); // This will log the response data
                const mostRecentNews = result.data.data.attributes.Body300x250.data; // Get the most recent news post from the response
                return mostRecentNews; // Return the most recent news post to be used in the component
            })
            .catch(error => {
                console.error("Error fetching advertisement:", error);
                throw error; // Rethrow the error so it can be handled in the component
            });
    }

    async getAdvertismentsFooter() {//used
        return await http.get(`/api/advertisement?populate=*`)
            .then(result => {
                // console.log(result.data.data.attributes.Header728x90.data); // This will log the response data
                const mostRecentNews = result.data.data.attributes.Footer728x90.data; // Get the most recent news post from the response
                return mostRecentNews; // Return the most recent news post to be used in the component
            })
            .catch(error => {
                console.error("Error fetching advertisement:", error);
                throw error; // Rethrow the error so it can be handled in the component
            });
    }

    async getAdvertismentsURL() {//used
        return await http.get(`/api/advertisement?populate=*`)
            .then(result => {
                // console.log(result.data.data.attributes.Header728x90.data); // This will log the response data
                const mostRecentNews = result.data.data.attributes.link; // Get the most recent news post from the response
                return mostRecentNews; // Return the most recent news post to be used in the component
            })
            .catch(error => {
                console.error("Error fetching advertisement url:", error);
                throw error; // Rethrow the error so it can be handled in the component
            });
    }
}