<template>
  <div>
    <drawer @toggleSidebar="toggleSidebar" :sidebar="sidebar" />
    <Header @toggleSidebar="toggleSidebar" />
    <!--====== POST LAYOUT 1 PART START ======-->

    <section class="post-layout-1-area pb-80">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="about-author-content">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/">Home</a></li>
                  <li class="breadcrumb-item"><a href @click.prevent="$router.go(-1)">{{
                    "World News" }}</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {{ truncateText(newsData.title, 30) }}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="post-layout-top-content">
              <div class="
                  post-categories
                  d-flex
                  justify-content-between
                  align-content-center
                ">
                <div class="categories-item">
                  <span>{{ "World News" }}</span>
                </div>
                <!-- <div class="categories-share">
                  <ul>
                    <li><i class="fas fa-comment"></i>45020</li>
                    <li><i class="fas fa-fire"></i>45020</li>
                  </ul>
                </div> -->
              </div>
              <div class="post-content">
                <h3 class="title">
                  {{ newsData.title }}
                </h3>
                <!-- <p>
                  The property, complete with 30-seat screening from room, a
                  100-seat amphitheater and a swimming pond with sandy shower…
                </p> -->
                <div class="thumb">
                  <!-- <img src="@/assets/images/post-layout.png" alt="" /> -->
                  <img :src="newsData.image" alt="" />
                </div>
              </div>
              <div class="post-author">
                <div class="author-info">
                  <div class="thumb">
                    <img src="@/assets/images/author1.png" alt="" />
                  </div>
                  <!-- <h5 class="title">{{ newsData[0].attributes.writtenBy }}</h5> -->
                  <ul>
                    <li>{{ new Date(newsData.publish_date).toLocaleDateString('en-GB') }}</li>
                    <span><br></span>
                    <!-- <li>Updated {{ new Date(newsData[0].attributes.updatedAt).toLocaleDateString() }}</li> -->
                  </ul>
                </div>
                <!-- <div class="author-social">
                  <ul>
                    <li>
                      <a href="#"><i class="fab fa-facebook-f"></i></a>
                    </li>
                    <li>
                      <a href="#"><i class="fab fa-twitter"></i></a>
                    </li>
                    <li>
                      <a href="#"><i class="fab fa-youtube"></i></a>
                    </li>
                    <li>
                      <a href="#"><i class="fab fa-instagram"></i></a>
                    </li>
                    <li>
                      <a href="#"><i class="fal fa-heart"></i></a>
                    </li>
                    <li>
                      <a href="#"><i class="fal fa-bookmark"></i></a>
                    </li>
                    <li>
                      <a href="#"><i class="fas fa-ellipsis-v"></i></a>
                    </li>
                  </ul>
                </div> -->
              </div>
              <!-- <div class="post-text mt-30">
                <p v-if="newsData.text" v-html="newsData.text.replace(/\n/g, '<br>')"></p>
              </div> -->
              <div class="post-text mt-30">
                <p v-if="newsData.text" v-html="formatText(newsData.text)"></p>
              </div>
              <div class="post-tags">
                <ul>
                  <li>
                    <a href="#"><i class="fas fa-tag"></i> Tags</a>
                  </li>
                  <!-- <li><a href="#">Health</a></li> -->
                  <li><a href="#">World</a></li>
                  <!-- <li><a href="#">Corona</a></li> -->
                </ul>
              </div>
              <!-- <div class="post-reader-text pt-50">
                <div class="row">
                  <div class="col-md-6">
                    <div class="post-reader-prev">
                      <span>PREVIOUS NEWS</span>
                      <h4 class="title">
                        <a href="#">Kushner puts himself in middle of white house’s
                          chaotic coronavirus response.</a>
                      </h4>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="post-reader-prev">
                      <span>NEXT NEWS</span>
                      <h4 class="title">
                        <a href="#">C.I.A. Hunts for authentic virus totals in china,
                          dismissing government tallies</a>
                      </h4>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
          <!-- <style-one :datas="Posts" /> -->
          <div class="col-lg-4">
            <div class="all-post-sidebar-style-2 mt-40">
              <div class="most-view-box">
                <div class="section-title">
                  <h3 class="title">Recent News</h3>
                </div>
                <template v-for="(data, index) in recentNews.slice(0, 5)">
                  <div :key="index" class="international-post-items">
                    <div class="international-item">
                      <div class="meta-category">
                        <!-- <span>Source: {{ data.source.name }}</span> -->
                        <span>{{ new Date(data.attributes.createdAt).toLocaleDateString()
                          }}</span>
                      </div>
                      <h5 class="title">
                        <!-- <router-link to="/posts/postOne">{{ data.title }}</router-link> -->
                        <router-link :to="'/news/' + (data.id)">{{
                          truncateText(data.attributes.title, 50)
                          }}</router-link>
                      </h5>
                      <div class="meta-user">
                        <span><i class="fal fa-user-circle"></i> {{ data.attributes.writtenBy
                          }}</span>
                      </div>
                      <div class="text-thumb d-flex align-items-center">
                        <p>
                          {{ truncateText(data.attributes.body, 80) }}
                        </p>
                        <div class="thumb">
                          <img
                            :src="`https://cms.tvwan.com.pg${data.attributes.image.data[0].attributes.formats.thumbnail.url}`"
                            alt="business" />
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <div class="add-area text-center">
      <!-- <a href="#"><img src="https://cms.tvwan.com.pg/uploads/728x90_6347b2ada5.png" alt="" /></a> -->
    </div>

    <!--====== ADD PART ENDS ======-->

    <footer-one />
    <div class="go-top-area">
      <div class="go-top-wrap">
        <div class="go-top-btn-wrap">
          <a href="#" class="go-top go-top-btn d-block">
            <i class="fa fa-angle-double-up"></i>
            <i class="fa fa-angle-double-up"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../../Utility/HeaderTwo/index";
// import StyleOne from "../../Utility/Sidebar/StyleOne.vue";
import Posts from "../../Data/NewsRowCard";
import FooterOne from "../../HomeTwo/Footer.vue";
// import OurLatestNews from "../../Utility/Common/OurLatestNews.vue";
import Drawer from "../../Mobile/Drawer.vue";
import NewsService from "../../../service/NewsService";
export default {
  components: { Header, FooterOne, Drawer },
  data() {
    return {
      Posts: Posts.data,
      sidebar: false,
      newsData: {
        attributes: {
          // createdAt: null,
          // smallImg: null
        }
      },
      filters: {
        nl2br: function (value) {
          if (!value) return '';
          return value.replace(/\n/g, '<br>');
        }
      },
      recentNews: {
        attributes: {
          // thumbnail: null
        }
      },
    }
  },
  methods: {
    formatText(text) {
            const lines = text.split('. ');
            let formattedText = '';
            for (let i = 0; i < lines.length; i += 3) {
                const paragraph = lines.slice(i, i + 3).join('. ') + '.';
                formattedText += '<p>' + paragraph + '</p>';
            }
            return formattedText.replace(/\n/g, '<br>');
        },
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    reloadPage() {
      location.reload();
    },
    topToBottom() {
      const result = document.querySelector(".go-top");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.classList.add("active");
      } else {
        result.classList.remove("active");
      }
    },
    truncateText(text, maxLength) {
      if (text.length > maxLength) {
        return text.slice(0, maxLength) + "...";
      }
      return text;
    },
    async getRecentNews() {
      const newsService = new NewsService();
      await newsService.getRecentNews()
        .then((data) => {
          this.recentNews = data;
          // console.log(this.recentNews)
        })
        .catch((error) => {
          console.error("Error fetching news:", error);
        });
    },
  },
  created: function () {
    document.addEventListener("scroll", this.topToBottom);

    let url = this.$route.params.url;
    // console.log('myString in WorldNewsPage:', this.$route.params.url);

    //service call to fetch newsdata of specific id
    const newsService = new NewsService();
    newsService.extractWorldNews(url)
      .then((data) => {
        this.newsData = data;
        // console.log("Yeet", newsData.url)
      })
      .catch((error) => {
        console.error("Error fetching news:", error);
      });
  },
  mounted() {
    this.getRecentNews();
  },
  watch: {
    $route(to, from) {
      // Reload the page whenever the route parameter 'name' changes
      if (to.params.id !== from.params.id) {
        this.reloadPage();
      }
    },
  },

};
</script>

<style></style>
