<template>
    <complaint />
</template>
  
<script>
import complaint from "../components/Complaints/index.vue";
export default {
    components: { complaint },
};
</script>
  
<style></style>
  