import http from "../http-common";

const baseUrl = 'https://cms.tvwan.com.pg/api';

export default {
    async postComplaintData(formData) {
        try {
            const response = await http.post(`${baseUrl}/complain-forms`, {
                data: formData,
            });
            return response.data;
        } catch (error) {
            console.error('Error submitting contact form:', error);
            throw error;
        }
    },
};